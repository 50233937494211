/** node library
 */
import { FC, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthentication } from "../../authentication/core/Provider";

/** custom library
 */
import { SideNavigation } from "../../layout/components/SideNavigation";
import Header from "../../layout/components/Header";

/** start
 */

const Dashboard: FC = () => {
  const { authentication } = useAuthentication();
  const [openMenu, setOpenMenu] = useState(true);

  return (
    <>
      {authentication === undefined ? (
        <Navigate to="/admin/login" replace={true} />
      ) : (
        <>
          <header className="fixed flex items-center text-lg bg-white w-full z-40 p-1">
            <Header openMenu={openMenu} setOpenMenu={setOpenMenu} />
          </header>

          <main className="z-20 h-full w-full py-16 bg-home-admin">
            <div className="flex relative md:w-full md:h-full">
              <div
                className={`fixed h-full w-52 ease-in duration-300 ${
                  !openMenu ? "-translate-y-full" : "-translate-y-0"
                }`}
              >
                <SideNavigation />
              </div>
              <div
                className={`py-8 ease-in duration-300 ${
                  openMenu ? "pl-52 w-full h-full" : "w-full h-full"
                }`}
              >
                <Outlet />
                {/* <Navigate to="/admin/user/list" /> */}
              </div>
            </div>
          </main>

          <footer>
            <div className="bg-blue-gray-50/50"></div>
          </footer>
        </>
      )}
    </>
  );
};

export default Dashboard;
