/** node library
 */
import axios from "axios";

/** custom library
 */
import { refreshTokenModel } from "../data/index";
import * as url_config from "../../payloads/URLs";

/** start
 */

export function getRefreshToken(refreshToken: string, userId: string) {
  return axios.post<refreshTokenModel>(url_config.default.getToken, {
    deviceUniqueId: "temp",
    refreshToken: refreshToken,
    userId,
  });
}
