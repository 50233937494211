/** node library
 */
import { useAuthentication } from "../../authentication";

/** custom library
 */

/** start
 */
const logo = require("../../../asset/image/logo.svg").default;

const Header = (props: any) => {
  const { logout } = useAuthentication();
  return (
    <>
      <button
        className="text-xl hover:bg-blue-300 rounded px-4 font-normal m-4"
        onClick={() => props.setOpenMenu(!props.openMenu)}
      >
        {props.openMenu ? (
          <i className="fa-solid fa-list"></i>
        ) : (
          <i className="fa-solid fa-bars"></i>
        )}
      </button>
      <div className="">
        <img className="inline-block h-10 w-44" src={logo} alt=""></img>
      </div>
      <div
        className="cursor-pointer w-full flex justify-end pr-12"
        onClick={() => logout()}
      >
        <i className="fa-solid fa-arrow-right-from-bracket"></i>
      </div>
    </>
  );
};

export default Header;
