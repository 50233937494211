/** node library
 */
import { NavLink } from "react-router-dom";

/** custom library
 */
import { MenuData } from "../../utility/data";

/** start
 */

export const SideNavigation = () => {
  let activeStyle = {
    textDecoration: "underline",
    fontWeight: "bold",
  };

  let menu: JSX.Element[] = [];

  MenuData.menus.forEach((item) => {
    menu.push(
      <NavLink
        key={item.key}
        to={item.link}
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
      >
        <ul className="hover:bg-blue-300 flex rounded-xl p-2">
          <div className="p-2"> {item.icon}</div>
          <li className="p-2 font-small">{item.text}</li>
        </ul>
      </NavLink>
    );
  });

  return (
    <section className="bg-white h-full min-w-fit">
      <div className="grid text-base px-2">{menu}</div>
    </section>
  );
};
