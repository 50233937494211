/** node library
 */

import { Fragment } from "react";
import ReactDOM from "react-dom";

/** custom library
 */
import MessageModal from "./MessageModal";
import CancelModal from "./CancelModal";

/** start
 */

const modalType = (props: any) => {
  switch (props.type) {
    case "Message":
      return ReactDOM.createPortal(
        <MessageModal
          title={props.title}
          Message="Coming Soon..."
          onClose={props.onClose}
          modalState={props.toggle}
          windowSize={props.windowSize}
        />,
        document.getElementById("overlay-root") as Element
      );
    case "Submit":
      return ReactDOM.createPortal(
        <MessageModal
          title={props.title}
          Message={"We will get back to you soon..."}
          onClose={props.onClose}
          modalState={props.toggle}
          windowSize={props.windowSize}
        />,
        document.getElementById("overlay-root") as Element
      );

    case "Cancel":
      return ReactDOM.createPortal(
        <CancelModal
          title={props.title}
          Message={"Are you Sure?"}
          onClose={props.onClose}
          onSure={props.onSure}
          modalState={props.toggle}
          windowSize={props.windowSize}
        />,
        document.getElementById("overlay-root") as Element
      );

    // case "Share":
    //   return ReactDOM.createPortal(
    //     <ShareModal
    //       title={props.title}
    //       onClose={props.onClose}
    //       modalState={props.toggle}
    //       windowSize={props.windowSize}
    //     />,
    //     document.getElementById("overlay-root") as Element
    //   );

    default:
      return;
  }
};

const Modal = (props: any) => {
  return <Fragment>{modalType(props)}</Fragment>;
};

export default Modal;
