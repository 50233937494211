/** node library
 */
import { Chip } from "@material-tailwind/react";
import { useState } from "react";
import Modal from "../../../UI/modal/Modal";
import axios from "axios";
import * as url from "../../../payloads/URLs";

/** custom library
 */

/** start
 */

export const CancelOrder = (props) => {
  const [modalState, setModalState] = useState(false);
  const { orderId, status, requestBody } = props;
  const modalType = "Cancel";

  const onCancel = () => {
    setModalState(true);
  };

  const handleModalClose = () => {
    setModalState(false);
  };
  const onSure = async () => {
    //network call

    debugger;
    try {
      let result = await axios.post(url.default.cancelOrder + "/" + orderId);
      console.log(result);
    } catch (error) {
      console.log(error.response.data.apierror.message);
      alert(error.response.data.apierror.message);
    } finally {
    }

    setModalState(false);
  };

  let pushButton = [];
  if (props.status !== "COMPLETED") {
    pushButton.push(
      <div key={props.orderId} onClick={onCancel} className="">
        <Chip variant="filled" color={"amber"} value={"cancel"} />
      </div>
    );
  }
  return (
    <>
      <div>{pushButton}</div>
      {modalState && (
        <Modal
          title={modalType}
          type={modalType}
          toggle={modalState}
          onClose={handleModalClose}
          onSure={onSure}
          windowSize="980"
        ></Modal>
      )}
    </>
  );
};
