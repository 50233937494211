/** node library
 */

/** custom library
 */

/** start
 */
export const BasicSpinner = () => {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="relative w-32 h-32 border-purple-200 border-2 rounded-full">
        <div className="w-32 h-32 border-purple-700 border-t-4 animate-spin rounded-full -m-0.5"></div>
      </div>
    </div>
  );
};
