/** node library
 */
import axios from "axios";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@material-tailwind/react";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";

/** custom library
 */
import "./index.scss";
import "./asset/css";
import "./asset/font";
import Routes from "./app/routes/Routes";
import { setupAxios, AuthenticationProvider, AuthenticationInitializer } from "./app/authentication/index";

/** start
 */
setupAxios(axios);
const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <QueryClientProvider client={queryClient}>
    <AuthenticationProvider>
      <AuthenticationInitializer>
        <ThemeProvider>
          <Routes />
          <ReactQueryDevtools initialIsOpen={false} />
        </ThemeProvider>
      </AuthenticationInitializer>
    </AuthenticationProvider>
  </QueryClientProvider>
);
