/** node library
 */
import { useFormik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";

/** custom library
 */
import { login } from "../../payloads";
import { getYupSchema } from "../../utility";
import { Button, Input } from "../../components";
import { useAuthentication } from "../../authentication";
import { data_signin, request_signin } from "../../utility/data";
import logo from "../../../asset/image/logo.svg";
import "./index.scss";

/** start
 */

const Login = () => {
  const navigate = useNavigate();
  const { saveAuthentication } = useAuthentication();

  const formik = useFormik({
    initialValues: request_signin,
    validationSchema: getYupSchema(data_signin),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const { data: authentication } = await login(
          values.username,
          values.password
        );
        saveAuthentication(authentication);

        setTimeout(() => {
          navigate("/admin", { replace: true });
        }, 1000);
      } catch (error) {
        setStatus("The login details is incorrect");
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl ring-2 ring-blue-500 lg:max-w-xl">
        <div className="m-auto justify-center items-center flex">
          <img
            className="inline-block h-10 w-44 m-auto"
            src={logo}
            alt=""
          ></img>
        </div>
        <form noValidate onSubmit={formik.handleSubmit} className="mt-6">
          <Input
            type="text"
            id="username"
            label="Username"
            value={formik.values["username"]}
            onChange={formik.handleChange}
            error={
              formik.touched["username"] && Boolean(formik.errors["username"])
            }
            helperText={formik.touched["username"] && formik.errors["username"]}
          />
          <Input
            type="password"
            id="password"
            label="Password"
            value={formik.values["password"]}
            onChange={formik.handleChange}
            error={
              formik.touched["password"] && Boolean(formik.errors["password"])
            }
            helperText={formik.touched["password"] && formik.errors["password"]}
          />

          {formik.isSubmitting && (
            <div className="flex justify-center">
              <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}

          <div className="mt-6">
            <Button type="submit">Login</Button>
          </div>
        </form>

        <p className="mt-8 text-xs font-light text-center text-gray-700">
          Don't have an account?
          <NavLink
            to="messages"
            className="font-medium text-black-900 hover:underline"
          >
            Sign up
          </NavLink>
        </p>
      </div>
    </div>
  );
};

export default Login;
