const url = "https://api.provisorr.com:8085/provisorr/api";
// const url = "https://e435-144-48-163-16.ngrok-free.app/provisorr/api";

const global = {
  // signing: url + "/auth/login",

  signing: url + "/auth/v1/login",
  getToken: url + "/auth/v2/refreshToken",

  userTable: url + "/admin/getUsers",
  feedbackTable: url + "/help",

  orderTable: url + "/admin/getAllOrders",

  getProfile: url + "/admin/getProfile",
  getImage: url + "/aws/download",

  getUserFeedback: url + "/review/",
  getUserEarning: url + "/earning/getAllEarning",
  getUserProjectList: url + "/admin/getAllOrdersByUser",

  cancelOrder: url + "/admin/orderCancel",
};

export default global;
