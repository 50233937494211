/** node library
 */
import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import React from "react";
/** custom library
 */

import { EARNINGS, OverView, RATING } from "./TabComponents";

export default function Tabbar(props) {
  const { userData } = props;
  const [activeTab, setActiveTab] = React.useState("OVERVIEW");
  const data = [
    {
      label: "OVERVIEW",
      icon: <i className="fa-regular fa-user"></i>,
      value: "OVERVIEW",
      desc: <OverView userData={userData} />,
    },
    {
      label: "RATING",
      icon: <i className="fa-regular fa-star"></i>,
      value: "RATING",
      desc: <RATING userData={userData} />,
    },
    // {
    //   label: "NOTIFICATIONS",
    //   icon: <i className="fa-regular fa-bell"></i>,
    //   value: "NOTIFICATIONS",
    //   desc: <NOTIFICATIONS />,
    // },
    {
      label: "EARNING",
      icon: <i className="fa-regular fa-money-bill-1"></i>,
      value: "EARNING",
      desc: <EARNINGS userData={userData} />,
    },
  ];
  return (
    <Tabs value={activeTab}>
      <TabsHeader
        className="rounded-none border-b border-blue-gray-50 bg-transparent p-0 "
        indicatorProps={{
          className:
            "bg-transparent border-b-2 border-purple-500 shadow-none rounded-none",
        }}>
        {data.map(({ label, value, icon }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setActiveTab(value)}
            className={activeTab === value ? " text-purple-800" : ""}>
            <div className="flex justify-center items-center gap-2 text-sm">
              {icon}
              {label}
            </div>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}
