/** node library
 */
import { FC, Suspense, lazy } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

/** custom library
 */
import { BasicSpinner } from "../components";
import Landing from "../page/Landing";
import Dashboard from "../page/admin/Dashboard";
import { FeedbackList } from "../page/admin/FeedbackList";
import { OrderList } from "../page/admin/OrderList";
import UserDetails from "../page/admin/UserDetails";
import { UserList } from "../page/admin/UserList";
import Login from "../page/authorization/Login";
import { PrivacyPolicy } from "../page/utility/PrivacyPolicy";
import { TermsCondition } from "../page/utility/TermsCondition";

/** start
 */

const Banners = lazy(() => import("../page/admin/Banners"));

const { PUBLIC_URL } = process.env;

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "/admin",
      element: <Dashboard />,
      children: [
        {
          index: true,
          // path: "/admin/user/list",
          element: <UserList />,
        },
        {
          path: "/admin/user/details",
          element: <UserDetails />,
        },
        {
          path: "/admin/feedback",
          element: <FeedbackList />,
        },
        {
          path: "/admin/order/list",
          element: <OrderList />,
        },
        {
          path: "/admin/banners",
          element: <Banners />,
        },
      ],
    },
    {
      path: "/admin/login",
      element: <Login />,
    },
    {
      path: "/terms_condition",
      element: <TermsCondition />,
    },
    {
      path: "/privacy_policy",
      element: <PrivacyPolicy />,
    },
  ],
  {
    basename: PUBLIC_URL,
  }
);

const Routes: FC = () => {
  return (
    <Suspense fallback={<BasicSpinner />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Routes;
