/** node library
 */
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
	Card,
	IconButton,
	MobileNav,
	Navbar,
	Typography,
} from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";

/** custom library
 */
import logo from "../../../asset/image/logo.svg";

/** start
 */

const NavigationBar = (props) => {
	const {
		homeRefClick,
		workRefClick,
		benefitRefClick,
		pbenefitRefClick,
		adownloadRefClick,
		contactRefClick,
		AppNavRefClick,
	} = props;

	const [openNav, setOpenNav] = useState(false);
	const clickNavigationRef = useRef(null);

	const handleNavigationMobileClickOutside = (event) => {
		if (!clickNavigationRef.current.contains(event.target)) {
			setOpenNav(false);
		}
	};

	useEffect(() => {
		window.addEventListener(
			"resize",
			() => window.innerWidth >= 960 && setOpenNav(false)
		);

		document.addEventListener(
			"click",
			handleNavigationMobileClickOutside,
			true
		);
	}, []);

	const navLists = (
		<ul className="mb-4 mt-2 flex flex-col gap-2 text-inherit lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-10">
			<Typography
				onClick={homeRefClick}
				as="li"
				variant="small"
				color="inherit"
				className="px-2 py-2 flex gap-1 items-center text-sm uppercase font-normal cursor-pointer rounded-lg hover:text-home-button hover:text-base transition-all duration-300">
				<NavLink
					to=""
					className="truncate flex items-center gap-1 font-normal font-Inter"
					end>
					Home
				</NavLink>
			</Typography>

			<Typography
				onClick={workRefClick}
				as="li"
				variant="small"
				color="inherit"
				className="px-2 py-2 flex gap-1 items-center text-sm uppercase font-normal cursor-pointer rounded-lg hover:text-home-button hover:text-base transition-all duration-300">
				<NavLink
					to=""
					className="truncate flex items-center gap-1 font-normal font-Inter"
					end>
					How it Works
				</NavLink>
			</Typography>

			<Typography
				onClick={benefitRefClick}
				as="li"
				variant="small"
				color="inherit"
				className="px-2 py-2 flex gap-1 items-center text-sm uppercase font-normal cursor-pointer rounded-lg hover:text-home-button hover:text-base transition-all duration-300">
				<NavLink
					to=""
					className="truncate flex items-center gap-1 font-normal font-Inter"
					end>
					Customer Benefit
				</NavLink>
			</Typography>

			<Typography
				onClick={pbenefitRefClick}
				as="li"
				variant="small"
				color="inherit"
				className="px-2 py-2 flex gap-1 items-center text-sm uppercase font-normal cursor-pointer rounded-lg hover:text-home-button hover:text-base transition-all duration-300">
				<NavLink
					to=""
					className="truncate flex items-center gap-1 font-normal font-Inter"
					end>
					Pro Benefit
				</NavLink>
			</Typography>

			{/* <Typography
        onClick={adownloadRefClick}
        as="li"
        variant="small"
        color="inherit"
        className="px-2 py-2 flex gap-1 items-center text-sm uppercase font-normal cursor-pointer rounded-lg hover:text-home-button hover:text-base transition-all duration-300"
      >
        <NavLink
          to=""
          className="truncate flex items-center gap-1 font-normal font-Inter"
          end
        >
          App Download
        </NavLink>
      </Typography> */}

			<Typography
				onClick={contactRefClick}
				as="li"
				variant="small"
				color="inherit"
				className="px-2 py-2 flex gap-1 items-center text-sm uppercase font-normal cursor-pointer rounded-lg hover:text-home-button hover:text-base transition-all duration-300">
				<NavLink
					to=""
					className="truncate flex items-center gap-1 font-normal font-Inter"
					end>
					Contact US
				</NavLink>
			</Typography>
		</ul>
	);

	return (
		<Card className="bg-white m-auto">
			<Navbar color="transparent" className="p-4 min-w-95 mx-auto">
				<div className="container mx-auto flex items-center justify-between text-black w-full">
					<div>
						<Link to="/" className="">
							<Typography className="font-Inter mr-4 ml-2  py-1.5 text-base font-bold whitespace-no-wrap uppercase">
								<img
									className="inline-block h-10 w-44"
									src={logo}
									alt=""></img>
							</Typography>
						</Link>
					</div>

					<div className="hidden lg:block">{navLists}</div>
					<div className="flex items-center gap-2">
						<div
							onClick={AppNavRefClick}
							className="gap-0 flex justify-end items-end">
							<button className="bg-home-button m-auto rounded-lg px-4 py-2 text-white font-semibold">
								Get The App
							</button>
						</div>
						<IconButton
							variant="text"
							size="sm"
							color="white"
							className="text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
							onClick={() => setOpenNav(!openNav)}>
							{openNav ? (
								<XMarkIcon
									strokeWidth={2}
									className="h-6 w-6"
								/>
							) : (
								<Bars3Icon
									strokeWidth={2}
									className="h-6 w-6"
								/>
							)}
						</IconButton>
					</div>
				</div>
				<MobileNav
					className="rounded-xl bg-white px-4 text-home-button hover:text-xl"
					open={openNav}
					ref={clickNavigationRef}>
					<div className="container mx-auto">{navLists}</div>
				</MobileNav>
			</Navbar>
		</Card>
	);
};
export default NavigationBar;
