/** node library
 */
import { Button, Dialog, DialogBody } from "@material-tailwind/react";

/** custom library
 */

/** start
 */
// screenWidth > 540 ? (
//     <Dialog
//       className="w-full"
//       open={props.modalState}
//       handler={props.onClose}
//       animate={{
//         mount: { scale: 1, y: 0 },
//         unmount: { scale: 0.9, y: -100 },
//       }}
//     >
//       {/* <DialogHeader className="flex items-start justify-between">
//         <>{props.title}</>
//         <Button
//           className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
//           onClick={props.onClose}
//         >
//           <span className=" text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
//             ×
//           </span>
//         </Button>
//       </DialogHeader> */}
//       <DialogBody
//         divider
//         className=" flex items-center justify-center rounded-lg"
//       >
//         <p className=" py-12 items-center text-base lg:text-xl font-bold">
//           {props.Message}
//         </p>
//       </DialogBody>
//       {/* <DialogFooter></DialogFooter> */}
//     </Dialog>
//   ) :

const CancelModal = (props) => {
  // const { windowSize } = props;
  // const screenWidth = windowSize[0];
  const dialogBox = (
    <Dialog
      className="w-full "
      open={props.modalState}
      handler={props.onClose}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}>
      <DialogBody divider className="flex-col item-center rounded-lg">
        <p className="p-12 items-center text-base lg:text-xl font-bold">
          {props.Message}
        </p>
        <div className="flex justify-end items-end gap-1 ">
          <Button
            className="mt-3 w-full sm:w-full  lg:w-44  bg-red-800 resize  gap-1 lg:gap-2  rounded-full"
            onClick={props.onSure}>
            <span className=" sm:text-xs text-base">Yes</span>
          </Button>
          <Button
            className="mt-3 w-full sm:w-full  lg:w-44  bg-green-800 resize  gap-1 lg:gap-2  rounded-full"
            onClick={props.onClose}>
            <span className=" sm:text-xs text-base">No</span>
          </Button>
        </div>
      </DialogBody>
      {/* <DialogFooter></DialogFooter> */}
    </Dialog>
  );
  return <>{dialogBox}</>;
};

export default CancelModal;
