/** node library
 */
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

/** custom library
 */
import * as url from "../../payloads/URLs";
import Tabbar from "../../layout/components/Tabbar";
import { BasicSpinner } from "../../components";

/** start
 */

interface userPerformance {
  badges?: string;
  overallTaskCount?: number;
  rating?: number;
  reviewsCount?: number;
}

interface userAddressModel {
  id?: number;
  city?: string;
  country?: string;
  details?: string;
  state?: string;
  postalCode?: string;
  latitude?: number;
  longitude?: number;
}

export type userDataType = {
  id?: number;
  description?: string;
  displayName?: string;
  email?: string;
  profileImageObjectKey?: string;
  username?: string;
  role: string;
  addresses: userAddressModel;
  Performance: userPerformance;
  phone: string;
};

const UserDetails = (props: any) => {
  const { state } = useLocation();
  const [userData, setUserData] = useState<userDataType>();
  const [overallTaskCount, setoverallTaskCount] = useState<string>("0");
  const [overallRating, setoverallRating] = useState<string>("0");
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState<string>();

  const avatar = require("../../../asset/image/Avatar.png");
  const task = require("../../../asset/image/task.svg").default;
  const rating = require("../../../asset/image/rating.svg").default;

  const fetchData = async () => {
    setLoading(true);
    let result = await axios.get(url.default.getProfile + "/" + state.id);
    if (result.status === 200) {
      console.log(result);
      setUserData(result.data.data);
      setoverallTaskCount(result.data.data.performance.overallTaskCount);
      setoverallRating(result.data.data.performance.rating);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchImage = async () => {
    if (userData?.profileImageObjectKey != null) {
      const res = await fetch(
        url.default.getImage + "/" + userData?.profileImageObjectKey
      );
      const imageBlob = await res.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setImg(imageObjectURL);
    } else setImg(avatar);
  };

  useEffect(() => {
    fetchImage();
  }, [userData]);
  return (
    <>
      {loading ? (
        <>
          <BasicSpinner />
        </>
      ) : (
        <div className="flex flex-row p-4">
          <div className="bg-white p-4 rounded-lg gap-2">
            <div className="flex justify-center">
              <img className="h-40 w-40 rounded-full" src={img} alt=""></img>
            </div>
            <div className="flex justify-center my-2 text-xl font-bold">
              {userData?.displayName}
            </div>
            <div className="flex justify-center text-xs font-light text-red-800 bg-red-200 rounded-md mx-10 my-3 py-1">
              {userData?.role}
            </div>

            <div className="flex justify-between my-4 gap-4 ">
              <div className="flex justify-center gap-2">
                <img className="" src={task} alt=""></img>
                <div className="flex-row justify-between">
                  <div className="text-md font-bold">{overallTaskCount}</div>
                  <div className="flex font text-[10px] whitespace-nowrap text-black  text-opacity-50">
                    Task Done
                  </div>
                </div>
              </div>
              <div className="flex justify-center gap-2">
                <img className="" src={rating} alt=""></img>
                <div className="flex-row justify-between ">
                  <div className="text-md font-bold">{overallRating}</div>
                  <div className="flex  whitespace-nowrap text-[10px] text-black text-opacity-50">
                    Total Rating
                  </div>
                </div>
              </div>
            </div>

            <div className="divide-y-2">
              <div className="py-4">
                <label className="text-xl font-medium">Details </label>
              </div>
              <div className="py-2">
                {/* <div className="flex items-center ">
                  <label className="font-medium">Username: </label>
                  <label className="px-2 text-sm font-light">
                    {userData?.username}
                  </label>
                </div> */}
                <div className="flex  items-center ">
                  <label className="font-medium">Email: </label>
                  <label className="px-2 text-sm font-light">
                    {userData?.email}
                  </label>
                </div>
                {/* <div className="flex  items-center ">
                  <label className="font-medium">Status: </label>
                  <label className="px-2 text-sm font-light">
                    {userData?.username}
                  </label>
                </div> */}
                <div className="flex  items-center ">
                  <label className="font-medium">Role: </label>
                  <label className="px-2 text-sm font-light">
                    {userData?.role}
                  </label>
                </div>
                <div className="flex  items-center ">
                  <label className="font-medium">Contect: </label>
                  <label className="px-2 text-sm font-light">
                    {userData?.phone}
                  </label>
                </div>

                <div className="flex  items-center ">
                  <label className="font-medium">Address: </label>
                  <label className="px-2 text-sm font-light">
                    {userData?.addresses.city},
                  </label>
                  <label className="text-sm font-light">
                    {userData?.addresses.country}
                  </label>
                </div>
              </div>
            </div>
            <div className="flex justify-center gap-2">
              {/* <button className=" bg-purple-500 text-white px-6 py-1 rounded-md ">
                Edit
              </button> */}
              <button className=" border-2 border-red-500 text-red-800 px-4 py-1 rounded-md ">
                Suspend
              </button>
            </div>
          </div>
          <div className="p-4 w-full">
            <Tabbar userData={userData} />
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetails;
