/** node library
 */
import React, { useState } from "react";

/** custom library
 */
import { SimpleTable } from "../../components/datatable/SimpleTable";
import {
  overView_column_value,
  rating_column_value,
  notification_column_value,
  earning_column_value,
} from "../../page/admin/data/UserDetailsColumn";
import {ChartdataYear,ChartdataMonth,ChartdataDay} from "../../utility/data"

import * as url from "../../payloads/URLs";
import { useAuthentication } from "../../authentication";
import Chart from "./chart";

export const OverView = (props: any) => {
  const { userData } = props;

  let element = [];
  if (userData && userData.id) {
    element.push(
      <SimpleTable
        columns={overView_column_value}
        url={url.default.getUserProjectList + "/" + userData.userId}
        title={"Project Overview"}
      />
    );
  }
  return <div>{element}</div>;
};

export const RATING = (props: any) => {

 
    
  const { userData } = props;
  let element = [];
  if (userData && userData.id) {
    element.push(
      <SimpleTable
        columns={rating_column_value}
        url={url.default.getUserFeedback  + userData.userId}
        title={"Project Rating"}
      />
    );
  }
  return <div>{element}</div>;
};

// export const NOTIFICATIONS = (props: any) => {
//   return (
//     <div>
//       <SimpleTable columns={notification_column_value} />
//     </div>
//   );
// };

export const EARNINGS = (props: any) => {


  const { userData } = props;
  const [startDateYear, setStartDateYear] = useState(
    new Date()
  );
  const [startDateMonth, setStartDateMonth] = useState(
    new Date()
  );
  const [startDateDay, setStartDateDay] = useState(
    new Date()
  );

  const  handleDateSelect = (date:any,type:string) => {
    if(type==='Year'){
      setStartDateYear(date);
    } else if(type==='Month') {
      setStartDateMonth(date);
    } else if(type==='Day') {
      setStartDateDay(date);
    }
  }


  

  let element = [];
  if (userData && userData.id) {
    element.push(
      <SimpleTable
        columns={earning_column_value}
        url={url.default.getUserEarning + "/" + userData.userId}
        title={"Project Earning"}
      />
    );
  }


  return <div>
    
    {element}
    <div>
      <Chart title="Total Profit" aspect={4} dataDay={ChartdataDay} dataMonth={ChartdataMonth} dataYear={ChartdataYear} dataPickerType="year" startDateMonth={startDateMonth} startDateYear={startDateYear} startDateDay={startDateDay} handleDateSelect={ handleDateSelect}></Chart>
      <Chart title="Total Profit" aspect={4} dataDay={ChartdataDay} dataMonth={ChartdataMonth} dataYear={ChartdataYear} dataPickerType="month" startDateMonth={startDateMonth} startDateYear={startDateYear} startDateDay={startDateDay} handleDateSelect={ handleDateSelect}></Chart>
      <Chart title="Total Profit" aspect={4} dataDay={ChartdataDay} dataMonth={ChartdataMonth} dataYear={ChartdataYear} dataPickerType="day"startDateMonth={startDateMonth} startDateYear={startDateYear} startDateDay={startDateDay} handleDateSelect={ handleDateSelect}></Chart>
    </div>
  
  </div>;
};
