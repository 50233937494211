export const Input = (props: any) => {
  return (
    <div className="mb-2">
      <label
        htmlFor={props.id}
        className="block text-sm font-semibold text-gray-800"
      >
        {props.label}
      </label>
      <input
        id={props.id}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-red-300 focus:outline-none focus:ring focus:ring-opacity-40"
      />
      {props.error ? (
        <label className="text-red-700">{props.helperText}</label>
      ) : (
        <></>
      )}
    </div>
  );
};
