import { Chip } from "@material-tailwind/react";
import { CancelOrder } from "../core/FunctionalTask";

export const overView_column_value = [
  {
    data: "orderId",
    name: "Order ID",
    selector: (row: any) => row.orderId,
    sortable: true,
    minWidth: "8rem",
    omit: true,
  },
  {
    data: "orderTitle",
    name: "Order Name",
    selector: (row: any) => row.orderTitle,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "negotiatedTime",
    name: "Execution Date",
    selector: (row: any) => row.negotiatedTime,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "customerName",
    name: "Customer",
    selector: (row: any) => row.customerName,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "serviceProviderName",
    name: "Service provider",
    selector: (row: any) => row.serviceProviderName,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "status",
    name: "Status",
    selector: (row: any) => row.status,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "negotiatedAmount",
    name: "Price",
    selector: (row: any) => "$ " + row.negotiatedAmount,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "cancelRequestSolved",
    name: "Cancel Request",
    selector: (row: any) =>
      row.cancelRequestSolved === false ? ( row.cancelRequested === true ?
        <CancelOrder orderId={row.orderId} status={row.status}></CancelOrder>: <Chip variant="filled" color={"blue"} value={"No"} />
      ) : (
        <Chip variant="filled" color={"red"} value={"Cancelled"} />
      ),
    sortable: true,
    minWidth: "8rem",
  },
];
export const rating_column_value = [
  {
    data: "id",
    name: "ID",
    selector: (row: any) => row.id,
    sortable: true,
    minWidth: "1rem",
    omit: true,
  },
  {
    data: "communicationRating",
    name: "Communication Rating",
    selector: (row: any) => row.communicationRating,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "professionalRating",
    name: "Professional Rating",
    selector: (row: any) => row.professionalRating,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "qualityRating",
    name: "Quality Rating",
    selector: (row: any) => row.qualityRating,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "overallRating",
    name: "Overall Rating",
    selector: (row: any) => row.overallRating,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "review",
    name: "Review",
    selector: (row: any) => row.review,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "reviewerName",
    name: "Reviewer Name",
    selector: (row: any) => row.reviewerName,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "spId",
    name: "spId",
    selector: (row: any) => row.spId,
    sortable: true,
    minWidth: "1rem",
    omit: true,
  },
];
export const notification_column_value = [
  {
    data: "id",
    name: "ID",
    selector: (row: any) => row.userId,
    sortable: true,
    minWidth: "1rem",
    omit: true,
  },
  {
    data: "notificationName",
    name: "Notification Name",
    selector: (row: any) => row.projectName,
    sortable: true,
    minWidth: "16rem",
  },
  {
    data: "date",
    name: "Date",
    selector: (row: any) => row.date,
    sortable: true,
    minWidth: "16rem",
  },
  {
    data: "tIME",
    name: "TIME",
    selector: (row: any) => row.rating,
    sortable: true,
    minWidth: "16rem",
  },
];
export const earning_column_value = [
  {
    data: "orderId",
    name: "orderId",
    selector: (row: any) => row.orderId,
    sortable: true,
    minWidth: "1rem",
    // omit: true,
  },
  {
    data: "serviceCharge",
    name: "serviceCharge",
    selector: (row: any) => row.serviceCharge,
    sortable: true,
    minWidth: "16rem",
  },
  {
    data: "status",
    name: "status",
    selector: (row: any) => row.status,
    sortable: true,
    minWidth: "16rem",
  },
  {
    data: "title",
    name: "title",
    selector: (row: any) => row.title,
    sortable: true,
    minWidth: "16rem",
  },
  {
    data: "earning",
    name: "earning",
    selector: (row: any) => row.earning,
    sortable: true,
    minWidth: "16rem",
  },
];
