/** node library
 */
import axios from "axios";
import { useState, useEffect } from "react";
import DataTable, { Direction } from "react-data-table-component";

/** custom library
 */
import { RotateSpinner } from "../loader/RotateSpinner";

/** start
 */

export interface tokenObject {
  token?: string;
  refreshToken?: string;
}

export const SimpleTable = (props: any) => {
  const { url, title, columns, requestBody, customStyles } = props;

  const [tableData, setTableData] = useState<object[]>([]);
  const [loading, setLoading] = useState(false);

  //   const SampleData = [
  //     {"cancelRequestSolved": true,
  //   "cancelRequested": true,
  //   "customerName": "string",
  //   "feedbackId": 0,
  //   "jobBidId": 0,
  //   "jobId": 0,
  //   "negotiatedAmount": 0,
  //   "negotiatedTime": "2023-07-04T16:42:09.273Z",
  //   "orderId": 0,
  //   "orderTitle": "string",
  //   "serviceProviderName": "string",
  //   "status": "string"}
  // ]

  const fetchData = async () => {
    setLoading(true);
    let result;
    if (title === "UserList") {
      result = await axios.get(url, requestBody);

      setTableData(result.data.users);
    } else if (title === "Project Earning") {
      try {
        result = await axios.get(url, requestBody);
        setTableData(result.data.data.earnings);
      } catch (error: any) {
        console.log(error.response.data.apierror.message);
        setTableData([]);
      } finally {
      }
    } else if (title === "Project Overview") {
      try {
        result = await axios.get(url, requestBody);
        setTableData(result.data.data);
      } catch (error: any) {
        console.log(error.response.data.apierror.message);
        setTableData([]);
      } finally {
      }
    } else if (title === "Project Rating") {
      try {
        result = await axios.get(url, requestBody);
        setTableData(result.data.data.reviews);
      } catch (error: any) {
        console.log(error.response.data.apierror.message);
        setTableData([]);
      } finally {
      }
    } else {
      result = await axios.get(url, requestBody);

      setTableData(result.data.data);
    }
    console.log(result);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DataTable
      title={title}
      // data={SampleData}
      data={tableData}
      columns={columns}
      fixedHeader={true}
      fixedHeaderScrollHeight="auto"
      striped={true}
      responsive={true}
      customStyles={customStyles}
      pointerOnHover={true}
      highlightOnHover={true}
      persistTableHead={true}
      direction={Direction.LTR}
      noDataComponent={"No Data Found"}
      pagination={true}
      progressPending={loading}
      progressComponent={<RotateSpinner />}
    ></DataTable>
  );
};
