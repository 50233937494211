export const MenuData = {
  menus: [
    {
      key: "all_orders",
      text: "All Orders",
      icon: <i className="fa-regular fa-circle"></i>,
      link: "/admin/order/list",
    },
    {
      key: "userlist",
      text: "User",
      icon: <i className="fa-regular fa-circle"></i>,
      // link: "/admin/user/list",
      link: "/admin",
    },
    {
      key: "feedback",
      text: "Feedback",
      icon: <i className="fa-regular fa-circle"></i>,
      link: "/admin/feedback",
    },
    {
      key: "banners",
      text: "Banners",
      icon: <i className="fa-regular fa-circle"></i>,
      link: "/admin/banners",
    },
    {
      key: "deals",
      text: "Deals",
      icon: <i className="fa-regular fa-circle"></i>,
      link: "/admin/deals",
    },
  ],
};

export const request_signin = {
  username: "",
  password: "",
};

export const data_signin = [
  {
    title: "Password",
    data: "password",
    type: "text",
    initialValue: "",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
      {
        type: "min",
        params: [5, "current min: 5 characters"],
      },
    ],
  },
  {
    title: "Username",
    data: "username",
    type: "text",
    initialValue: "",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
      {
        type: "min",
        params: [5, "current min: 5 characters"],
      },
    ],
  },
];

export const ChartdataMonth = [
  { name: "1", Total: 1200 },
  { name: "2", Total: 2100 },
  { name: "3", Total: 800 },
  { name: "4", Total: 1600 },
  { name: "5", Total: 900 },
  { name: "6", Total: 1700 },
  { name: "7", Total: 1600 },
  { name: "8", Total: 900 },
  { name: "9", Total: 1700 },
  { name: "10", Total: 1200 },
  { name: "11", Total: 2100 },
  { name: "12", Total: 800 },
  { name: "13", Total: 1200 },
  { name: "14", Total: 2100 },
  { name: "15", Total: 800 },
  { name: "16", Total: 1600 },
  { name: "17", Total: 900 },
  { name: "18", Total: 1700 },
  { name: "19", Total: 1600 },
  { name: "20", Total: 900 },
  { name: "21", Total: 1700 },
  { name: "22", Total: 1200 },
  { name: "23", Total: 2100 },
  { name: "24", Total: 800 },
  { name: "25", Total: 1200 },
  { name: "26", Total: 2100 },
  { name: "27", Total: 800 },
  { name: "28", Total: 1600 },
  { name: "29", Total: 900 },
  { name: "30", Total: 1700 },
  { name: "31", Total: 1600 },
];
export const ChartdataYear = [
  { name: "January", Total: 120 },
  { name: "February", Total: 210 },
  { name: "March", Total: 800 },
  { name: "April", Total: 160 },
  { name: "May", Total: 900 },
  { name: "June", Total: 170 },
  { name: "July", Total: 160 },
  { name: "Aug", Total: 90 },
  { name: "Sep", Total: 170 },
  { name: "Oct", Total: 120 },
  { name: "Nov", Total: 210 },
  { name: "Dec", Total: 80 },
];
export const ChartdataDay = [
  { name: "Sat", Total: 120 },
  { name: "Sun", Total: 210 },
  { name: "Mon", Total: 800 },
  { name: "Tue", Total: 160 },
  { name: "Wed", Total: 900 },
  { name: "Thur", Total: 170 },
  { name: "Fri", Total: 160 },
];
