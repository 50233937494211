export const column_value = [
  {
    data: "creatorId",
    name: "Creator Id",
    selector: (row: any) => row.creatorId,
    sortable: true,
    minWidth: "4rem",
    omit: true,
  },
  {
    data: "creatorType",
    name: "Creator Type",
    selector: (row: any) =>
      row.creatorType === 1
        ? "customer"
        : row.creatorType === 2
        ? "service provider"
        : row.creatorType,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "email",
    name: "Email",
    selector: (row: any) => row.email,
    sortable: true,
    minWidth: "16rem",
  },
  {
    data: "feedbackDescription",
    name: "Feedback Description",
    selector: (row: any) => row.feedbackDescription,
    sortable: true,
    minWidth: "32rem",
  },
  {
    data: "orderId",
    name: "Order Id",
    selector: (row: any) => row.orderId,
    sortable: true,
    minWidth: "4rem",
  },
  {
    data: "remarks",
    name: "Remarks",
    selector: (row: any) => row.remarks,
    sortable: true,
    minWidth: "16rem",
  },
  {
    data: "solved",
    name: "Solved",
    selector: (row: any) => row.solved,
    sortable: true,
    minWidth: "4rem",
  },
  {
    data: "type",
    name: "Type",
    selector: (row: any) =>
      row.type === 1
        ? "order cancel"
        : row.type === 2
        ? "app feedback"
        : row.type,
    sortable: true,
    minWidth: "8rem",
  },
];
