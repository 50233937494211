/** node library
 */
import React, {FC, useRef, useState, useEffect, useContext, createContext} from "react";

/** custom library
 */
import { AuthenticationModel } from "../data";
import * as authenticationHandler from "./Handler";
import { getRefreshToken } from "../requests/TokenRequest";

/** start
 */

type AuthenticationContextType = {
  authentication: AuthenticationModel | undefined;
  saveAuthentication: (authentication: AuthenticationModel | undefined) => void;
  logout: () => void;
};

const AuthenticationContextState = {
  authentication: authenticationHandler.getAuthentication(),
  saveAuthentication: () => {},
  logout: () => {},
};

const AuthenticationContext = createContext<AuthenticationContextType>(AuthenticationContextState);

export const useAuthentication = () => {
  return useContext(AuthenticationContext);
};

export const AuthenticationProvider: FC<{ children?: React.ReactNode }> = ({children}) => {
  const [authentication, setAuthentication] = useState< AuthenticationModel | undefined >(authenticationHandler.getAuthentication());

  const saveAuthentication = ( authentication: AuthenticationModel | undefined ) => {
    setAuthentication(authentication);
    if (authentication) {
      authenticationHandler.setAuthentication(authentication);
    } else {
      authenticationHandler.removeAuthentication();
    }
  };

  const logout = () => {
    saveAuthentication(undefined);
  };

  return (
    <AuthenticationContext.Provider
      value={{
        authentication,
        saveAuthentication,
        logout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const AuthenticationInitializer: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { authentication, saveAuthentication, logout } = useAuthentication();
  const didRequest = useRef(false);
  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async (tokenModel: AuthenticationModel) => {
      try {
        const { data: refreshTokenModel } = await getRefreshToken(
          tokenModel.refreshToken,
          tokenModel.userId
        );
        if(authentication){
          let tempAuthentication: AuthenticationModel = authentication;
          tempAuthentication.authToken = refreshTokenModel.accesstoken;
          saveAuthentication(tempAuthentication);
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
      }

      return () => (didRequest.current = true);
    };

    if (authentication && authentication.refreshToken) {
      requestUser(authentication);
    } else {
      logout();
    }
    // eslint-disable-next-line
  }, []);

  return <>{children}</>;
};
