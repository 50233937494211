/** node library
 */
import { Typography } from "@material-tailwind/react";

//Images in Png 2x
// import MobileImageOne from "../../asset/image/home_png/MobileImageOne.png";
import MobileImageFive from "../../asset/image/home_png/MobileImageFive.png";
import MobileImageTwo from "../../asset/image/home_png/MobileImageTwo.png";

//Images in svg
import MobileImageOne from "../../asset/image/home/MobileImageOne.svg";

import { useEffect, useState } from "react";
import bid_icon from "../../asset/image/home_icon/bid.svg";
import chat_icon from "../../asset/image/home_icon/chat.svg";
import done_icon from "../../asset/image/home_icon/doneThumbsUp.svg";
import login_icon from "../../asset/image/home_icon/login.svg";
import notepad_icon from "../../asset/image/home_icon/notepad.svg";

import Modal from "../UI/modal/Modal";
import CustomerBenefit from "./Landing/CustomerBenefit";
import LandingCarousel from "./Landing/LandingCarousel";
import ProBenefit from "./Landing/ProBenefit";

/** start
 */

export const Home = (props) => {
	const {
		refWorks,
		refBenefits,
		refPBenefits,
		refADownload,
		refContact,
		refAppNav,
		AppNavRefClick,
		contactRefClick,
	} = props;

	const [modalState, setModalState] = useState(false);
	const [modalType, setModalType] = useState("");
	const [windowSize, setWindowSize] = useState([
		window.innerWidth,
		window.innerHeight,
	]);

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSize([window.innerWidth, window.innerHeight]);
		};

		window.addEventListener("resize", handleWindowResize);

		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, [windowSize]);

	const text =
		" On Provisorr we connect customers to a pool of qualified Service \n Providers.Customers select and chat with Service Providers based on \n price, availability and review history. Service Providers bid on \n projects that fit their scope of work\n";

	const Submit = "Submit";

	const handleModalClose = () => {
		setModalState(false);
	};

	const formSubmit = () => {
		setModalType(Submit);
		setModalState(true);
	};

	const onClickTerms = () => {
		window.location.pathname = "/terms_condition";
	};

	const onClickPolicy = () => {
		window.location.pathname = "/privacy_policy";
	};

	const FirstDiv = (
		<div className="flex flex-col-reverse lg:flex-row">
			<div className=" flex flex-wrap p-4 content-center md:w-2/3 w-full">
				<Typography variant="h1" color="black" className="mb-6 ">
					<div className="flex gap-2">
						<p className="text-left md:text-6xl text-3xl text-black font-bold">
							{" "}
							Find
						</p>
						<div className="md:text-6xl text-3xl text-blue-800 font-bold">
							<LandingCarousel />
						</div>
					</div>
					<p className="text-left md:text-6xl text-3xl text-black font-bold">
						Easily!!
					</p>
				</Typography>
				<Typography
					variant="lead"
					color="black"
					className="opacity-80 text-left pb-8 text-black">
					Chat with Local Pros –{" "}
					<span className="text-blue font-bold">
						Plumbers, Electricians, Landscapers, Handymen,
					</span>{" "}
					Free on Provisorr! Say Hello to Hassle-Free Home Fixes!
				</Typography>
				<div className="flex justify-evenly gap-2">
					<button
						onClick={AppNavRefClick}
						className=" py-2 px-2 bg-home-button text-white font-semibold rounded-lg mt-2 items-center sm:text-base text-sm hover:text-lg hover:font-bold transition-all duration-300">
						Get the App
					</button>
					<button
						onClick={contactRefClick}
						className=" py-2 px-2 bg-white text-home-button font-semibold rounded-lg mt-2 items-center border-home-button border-2 hover:text-lg hover:font-bold transition-all duration-300">
						Contact Us
					</button>
				</div>
			</div>
			<div className="flex justify-end m-auto w-full">
				<div className="">
					<img
						className="h-auto w-full object-fill"
						src={MobileImageOne}
						alt=""></img>
				</div>
			</div>
		</div>
	);

	const SecondDiv = (
		<div ref={refWorks} className="flex flex-col lg:flex-row pt-32">
			<div className=" flex justify-start w-full md:w-2/3">
				<div className=" flex flex-row">
					<img
						className="w-auto h-auto object-fill"
						src={MobileImageTwo}
						alt=""></img>
				</div>
			</div>
			<div className=" flex flex-col justify-center m-auto p-4 w-full md:w-1/3">
				<Typography
					variant="h2"
					color="black"
					className="mb-6 text-left pb-6">
					How it works?
				</Typography>

				<div className="flex justify-start gap-3 mb-4">
					<div className="bg-white border-home-icon_border border-2 m-auto p-4 rounded-lg w-1/5 flex items-center justify-center">
						<img
							src={notepad_icon}
							alt=""
							className="h-full w-full"
						/>
					</div>
					<div className="w-4/5 flex items-center">
						<Typography
							variant="h4"
							color="black"
							className="text-left text-xl lg:text-2xl font-extrabold">
							Post your task
						</Typography>
					</div>
				</div>
				<div className="flex justify-start gap-3 mb-4">
					<div className="bg-white border-home-icon_border border-2 m-auto p-4 rounded-lg w-1/5 flex items-center justify-center">
						<img src={chat_icon} alt="" className="h-full w-full" />
					</div>
					<div className="w-4/5 flex items-center">
						<Typography
							variant="h4"
							color="black"
							className=" text-left text-xl lg:text-2xl font-extrabold">
							Chat with pros
						</Typography>
					</div>
				</div>
				<div className="flex justify-start gap-3 mb-4">
					<div className="bg-white border-home-icon_border border-2 m-auto p-4 rounded-lg w-1/5 flex items-center justify-center">
						<img src={done_icon} alt="" className="h-full w-full" />
					</div>
					<div className="w-4/5 flex items-center">
						<Typography
							variant="h4"
							color="black"
							className=" text-left text-xl lg:text-2xl font-extrabold">
							Get it done
						</Typography>
					</div>
				</div>
			</div>
		</div>
	);

	const forthDiv_pertial =
		windowSize[0] > 540 ? (
			<div className="row-span-3 flex flex-col justify-center m-auto pl-12 pr-4">
				<div className="flex flex-row justify-end pb-4">
					<div className="w-4/5 px-4">
						<Typography
							variant="h4"
							color="black"
							className="text-end text-xl lg:text-2xl">
							Login
						</Typography>
						<Typography className=" pb-4 text-sm text-gray-700 text-end">
							You can login and get access to all available
							features.
						</Typography>
					</div>
					<div className="bg-white border-home-icon_border border-2 m-auto p-4 rounded-lg w-1/6">
						<img
							src={login_icon}
							alt=""
							className="h-full w-full"></img>
					</div>
				</div>
				<div className="flex flex-row justify-end pb-4">
					<div className="w-4/5 px-4">
						<Typography
							variant="h4"
							color="black"
							className="text-end text-xl lg:text-2xl">
							Build Profile
						</Typography>
						<Typography className=" pb-4 text-sm text-gray-700 text-end">
							Build your professional profile by describing
							yourself or your company, uploading images of
							previous work and Certifications if available.
						</Typography>
					</div>
					<div className="bg-white border-home-icon_border border-2 m-auto p-4 rounded-lg w-1/6">
						<img
							src={notepad_icon}
							alt=""
							className="h-full w-full"></img>
					</div>
				</div>
				<div className="flex flex-row justify-end">
					<div className="w-4/5 px-4">
						<Typography
							variant="h4"
							color="black"
							className="text-end text-xl lg:text-2xl">
							Bid & Rebid
						</Typography>
						<Typography className=" pb-4 text-sm text-gray-700 text-end">
							You can bid and rebid on the customers’ posts.
						</Typography>
					</div>
					<div className="bg-white border-home-icon_border border-2 m-auto p-4 rounded-lg w-1/6">
						<img
							src={bid_icon}
							alt=""
							className="h-full w-full"></img>
					</div>
				</div>
			</div>
		) : (
			<div className="row-span-3 flex flex-col justify-center m-auto p-4">
				<div className="flex flex-row justify-start pb-4">
					<div className="bg-white border-home-icon_border border-2 m-auto p-4 rounded-lg w-1/6">
						<img
							src={login_icon}
							alt=""
							className="h-full w-full"></img>
					</div>
					<div className="w-4/5 px-4">
						<Typography
							variant="h4"
							color="black"
							className="text-start text-xl lg:text-2xl">
							Login
						</Typography>
						<Typography className=" pb-4 text-sm text-gray-700 text-start">
							You can login and get access to all available
							features.
						</Typography>
					</div>
				</div>
				<div className="flex flex-row justify-start pb-4">
					<div className="bg-white border-home-icon_border border-2 m-auto p-4 rounded-lg w-1/6">
						<img
							src={notepad_icon}
							alt=""
							className="h-full w-full"></img>
					</div>
					<div className="w-4/5 px-4">
						<Typography
							variant="h4"
							color="black"
							className="text-start text-xl lg:text-2xl">
							Build Profile
						</Typography>
						<Typography className=" pb-4 text-sm text-gray-700 text-start">
							Build your professional profile by describing
							yourself or your company, uploading images of
							previous work and Certifications if available.
						</Typography>
					</div>
				</div>
				<div className="flex flex-row justify-start">
					<div className="bg-white border-home-icon_border border-2 m-auto p-4 rounded-lg w-1/6">
						<img
							src={bid_icon}
							alt=""
							className="h-full w-full"></img>
					</div>
					<div className="w-4/5 px-4">
						<Typography
							variant="h4"
							color="black"
							className="text-start text-xl lg:text-2xl">
							Bid & Rebid
						</Typography>
						<Typography className=" pb-4 text-sm text-gray-700 text-start">
							You can bid and rebid on the customers’ posts.
						</Typography>
					</div>
				</div>
			</div>
		);

	const FifthDiv = (
		<div className="flex flex-wrap py-6 justify-center">
			<div>
				<img
					className="object-right h-auto w-120"
					src={MobileImageFive}
					alt=""
				/>
			</div>
			<div className="flex flex-col flex-wrap p-4 my-auto">
				<Typography
					variant="h2"
					color="white"
					className="text-left text-3xl lg:text-4xl">
					Download the app
				</Typography>
				<Typography
					variant="h2"
					color="white"
					className="mb-6 text-left text-3xl lg:text-4xl">
					and get your task done today !!
				</Typography>

				<div className="mt-3 flex flex-wrap gap-3">
					<a
						className="bg-home-download_button border-white border-2 px-6 py-2 rounded-xl text-white flex flex-wrap gap-2 items-center"
						href="https://apps.apple.com/ca/app/provisorr/id6447469839"
						target="_blank"
						rel="noopener noreferrer">
						<i className="fa-brands fa-apple fa-3x flex-auto " />
						<div className="flex flex-wrap flex-col justify-start items-start">
							<p className="text-xs">Download the app</p>
							<p className=" text-2xl">App Store</p>
						</div>
					</a>
				</div>
			</div>
		</div>
	);

	const SixthDiv = (
		<div
			ref={refContact}
			className="relative py-4 bg-home-button rounded-t-3xl h-full w-full bg-[url('../../asset/image/frame1.png')] bg-no-repeat bg-center bg-100">
			<div className="p-4 pt-16 md:p-12 h-full w-full">
				<div className="flex flex-row flex-wrap p-4 h-full w-full">
					<Typography
						variant="h2"
						color="white"
						className="text-left text-3xl lg:text-4xl w-full h-full mb-4">
						Download App
					</Typography>
					<div className="flex flex-wrap gap-3 w-full">
						<a
							className="bg-home-download_button border-white border-2 px-6 py-1 rounded-xl text-white flex flex-wrap gap-2 items-center"
							href="https://apps.apple.com/ca/app/provisorr/id6447469839"
							target="_blank"
							rel="noopener noreferrer">
							<i className="fa-brands fa-apple fa-3x flex-auto " />
							<div className="flex flex-wrap flex-col justify-start items-start">
								<p className="text-xs">Download the app</p>
								<p className="text-lg">App Store</p>
							</div>
						</a>
						{/* <button
              className="bg-home-download_button border-white border-2 px-6 py-1 rounded-xl text-white flex flex-wrap gap-2 items-center"
              onClick={() => messageButtonClick()}
            >
              <img className="h-auto w-10" src={playStore_icon} alt="" />
              <div className="flex flex-wrap flex-col justify-start items-start">
                <p className="text-xs">Andriod App on</p>
                <p className="text-lg">Google Play</p>
              </div>
            </button> */}
					</div>
					<Typography
						variant="h6"
						color="white"
						className="md:text-right w-full h-full cursor-pointer mr-36 mt-4">
						<div onClick={onClickTerms} className="text-sm">
							Terms & Condition
						</div>
					</Typography>
					<Typography
						variant="h6"
						color="white"
						className="md:text-right w-full h-full cursor-pointer mr-36 mt-4">
						<div onClick={onClickPolicy} className="text-sm">
							Privacy & Policy
						</div>
					</Typography>
				</div>
			</div>
			<div className="absolute flex top-0 right-3 transform lg:-translate-x-1/2 -translate-y-3/4 lg:-translate-y-2/4">
				<div className="bg-white h-80 w-72 md:w-96 rounded-xl shadow-md px-4 text-sm font-medium">
					<p className="text-lg font-bold text-home-button mt-2">
						Contact Us
					</p>
					<p className="text-xs text-gray-600 mt-4">
						Send your query to us and we’ll solve your issue within
						24 hours or just say Hi!
					</p>

					<div className="flex flex-col flex-wrap  justify-center items-center pt-4 gap-4">
						<form className="flex flex-col gap-2 justify-center items-center w-full h-full">
							<input
								type="text"
								placeholder="Your name"
								className="border-2 p-1 w-full rounded-lg"
								onChange={() => {}}
							/>
							<input
								type="text"
								placeholder="Your@email.here"
								className="border-2 p-1 w-full rounded-lg"
								onChange={() => {}}
							/>
							<textarea
								placeholder="type your text here"
								className="border-2 p-3 w-full rounded-lg"
								onChange={() => {}}
							/>
						</form>
						<button
							className="bg-home-button flex-grow text-white py-2 w-full rounded-lg hover:text-lg hover:font-bold hover:-mt-2 transition-all duration-300"
							onClick={() => formSubmit()}>
							Send
						</button>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<div className="h-full w-full">
				<div className="container mx-auto pt-24">
					{FirstDiv}
					{SecondDiv}
					<div ref={refBenefits} className="mb-10">
						<CustomerBenefit windowSize={windowSize} />
					</div>
					<div ref={refPBenefits}>
						<ProBenefit windowSize={windowSize} />
					</div>
				</div>
				<div ref={refAppNav}></div>
				<div ref={refADownload} className="mt-4 pt-20">
					<div className="mx-auto bg-[url('../../asset/image/frame2.png')] bg-no-repeat bg-center bg-100 bg-home-button">
						{FifthDiv}
					</div>
				</div>
				<div className="h-64 lg:h-56 w-full pt-32"></div>
				{SixthDiv}
			</div>

			{modalState && (
				<Modal
					title={modalType}
					type={modalType}
					toggle={modalState}
					onClose={handleModalClose}
					windowSize={windowSize}></Modal>
			)}
		</>
	);
};
