/** node library
 */
import { Link } from "react-router-dom";
import { Typography } from "@material-tailwind/react";

/** custom library
 */
import logo from "../../../asset/image/logo.svg";

/** start
 */

export const PrivacyPolicy = () => {
  return (
    <div className="h-full w-full p-8">
      <div className="h-full w-full p-4 rounded-lg">
        <div className="flex w-full m-auto items-center justify-center p-8">
          <Link to="/" className="">
            <img className="inline-block h-12 w-50" src={logo} alt=""></img>
          </Link>
        </div>
        <div className="p-8 items-center justify-center">
          <Typography variant="h4" color="black" className="mb-6 text-center">
            PRIVACY POLICY
          </Typography>
        </div>
        <div className="container items-center justify-center m-auto bg-white p-8 rounded-xl">
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Introduction
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              1. This Privacy Policy Statement (the “Privacy Policy”) contains
              the policies, procedures and practices to be followed by Provisorr
              Inc. and any of its present or future subsidiaries (the “Company”)
              pertaining to the collection, use and disclosure of personal
              information (the “Personal Information”) of an identifiable person
              (the “Individual”) that is a present, future or former user (the
              “User) of the Company’s services or affiliated services..
              <br />
              <br />
              2. The Company recognizes the confidential nature of the Personal
              Information in its care and is accountable for the compliance of
              itself and its directors, officers, management, employees,
              representatives and agents including consultants and independent
              contractors (the “Staff”) in protecting this Personal Information.
              <br />
              <br />
              3. For the purpose of this Privacy Policy, the term “Personal
              Information” has the meaning of any information or collection of
              information in any form, whether oral, electronic or written that
              pertains to the Individual excluding information that is publicly
              available in its entirety. Personal Information will also include
              any publicly available information that is combined with
              non-publicly available information.
              <br />
              <br />
              4. Personal Information includes but is not limited to name, home
              address, home phone number, home email address, identity
              verification information, Social Insurance Number, physical
              description, age, gender, salary, education, professional
              designation, personal hobbies and activities, medical history,
              employment history, and credit history.
              <br />
              <br />
              5. Personal Information will not include the Individual's business
              title, and business address and contact information when used or
              disclosed for the purposes of reasonable business communication.
              <br />
              <br />
              6. The Company will implement policies and procedures that give
              effect to this Privacy Policy including procedures to protect and
              secure Personal Information, procedures to receive, investigate
              and resolve complaints, procedures to ensure adequate training of
              the Staff concerning the Company's privacy policies, and
              procedures to distribute new and current information pertaining to
              the Company's Privacy Policy.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Corporate Privacy Policy
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              7. The Company and the Staff will at all times respect the
              confidentiality of the Personal Information placed in its care.
              The Company will endeavour to ensure that the policies affecting
              the collection, storage and disclosure of Personal Information
              reflect the confidential nature of the information.
              <br />
              <br />
              8. The Company will comply with all applicable privacy legislation
              and regulations in force now and in the future related to
              protecting the confidentiality of Personal Information.
              <br />
              <br />
              Purposes for which Personal Information is Collected and Used
              <br />
              <br />
              9. Personal Information will be collected, used and disclosed for
              purposes of providing Individuals with access to and use of the
              Company’s services, website, and related applications[A2] ,
              including administering payments, obtaining contractor reviews,
              complying with applicable laws. In addition, the Company collects
              and uses personal information in the following circumstances:
              <br />
              <br />· Contractor ID Verification and Background Checks:
              Contractors who wish to perform site visits must complete a
              third-party ID verification and may choose to complete a criminal
              background check [A3] on an optional basis. The Company will
              obtain the contractor’s consent prior to conducting an ID
              verification or optional criminal background check. To verify a
              contractor’s identity, a third-party collects a photo of the front
              and back of the contractor’s driver’s license or other photo ID,
              and a selfie that the contractor takes of themselves[A4] . The
              third-party uses the contractor’s selfie and the photo on the
              contractor’s driver’s license to verify that the contractor is who
              they say they are. The contractor’s selfie and the photos of the
              contractor’s driver’s license are only kept as long as is needed
              to complete the verification process. [A5].The contractor can take
              photo of their certifications or licenses and add them to their
              profile gallery if they want to. But Provisorr will not verify
              them because verification is fully optional. · Contractor Site
              Visits: During site visits, contractors may collect Personal
              Information such as photos, home measurements, and customer
              project plans and preferences for purposes such as developing
              plans for a project, determining which materials are required, and
              recording homeowner instructions and preferences.
              <br />
              <br />
              10. The purposes for collecting Personal Information will be
              documented by the Company. Personal Information will only be used
              for the stated purpose or purposes for which it was originally
              collected unless you otherwise consent. The purposes for which
              Personal Information is being collected will be identified orally
              or in writing to the Individual before it is collected. The person
              collecting the information will be able to explain the purpose at
              the time that the information is collected.
              <br />
              <br />
              11. The Company may use Personal Information for a purpose other
              than the originally stated purpose where the new purpose is
              required by law or where the Company has obtained consent in
              writing from the affected Individual for each new purpose.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-Bold"
          >
            Knowledge and Consent
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              12. Knowledge and consent are required from the affected
              Individual for the collection, use and disclosure of all Personal
              Information subject to exceptions noted elsewhere in the Privacy
              Policy statement.
              <br />
              <br />
              13. Consent will not be obtained through deception or
              misrepresentation.
              <br />
              <br />
              14. Any use or disclosure of Personal Information will be within
              the reasonable expectations of the Individual.
              <br />
              <br />
              15. Subject to legal and contractual obligations, an Individual
              may withdraw their consent on reasonable notice.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Legislation and Regulation
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              16. Where the Company’s Users are living in different
              jurisdictions the specific rights and obligations of Users may
              vary between jurisdictions.
              <br />
              <br />
              17. The Company is subject to the privacy legislation in all
              jurisdictions in which the Company operates. If any term,
              covenant, condition or provision of this Privacy Policy is held by
              a court of competent jurisdiction to be invalid, void or
              unenforceable, it is the intent of this Privacy Policy that the
              scope of the rights and obligations of the Privacy Policy be
              reduced only for the affected jurisdiction and only to the extent
              deemed necessary under the laws of the local jurisdiction to
              render the provision reasonable and enforceable and the remainder
              of the provisions of the Privacy Policy statement will in no way
              be affected, impaired or invalidated as a result.
              <br />
              <br />
              18. Where this Privacy Policy provides greater rights and
              protections to the Individual than the available governing law,
              the terms of this Privacy Policy will prevail wherever allowed by
              law
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Scope and Application
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              19. The rights and obligations described in this Privacy Policy
              will apply to all Individuals. The Company and the Staff must
              comply with the policies, procedures and practices described in
              the Privacy Policy.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Collection of Personal Information
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              20. The type and amount of Personal Information collected by the
              Company will be limited to the minimum necessary to accomplish
              reasonable business purposes. Personal Information will not be
              collected maliciously, indiscriminately or without a reasonable
              business purpose.
              <br />
              <br />
              21. Personal Information will be collected using fair and lawful
              means.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Access by Authorized Company Representatives
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              22. All Personal Information will be released internally only on a
              need-to-know basis. In the course of normal and reasonable
              business practices it is the policy of the Company to grant
              designated Company representatives access to Personal Information
              files. This access will not exceed that necessary to accomplish
              the specific business function of the Company representative nor
              the purpose for which the information was originally collected.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Accuracy of Personal Information
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              23. The Company will endeavour to ensure that all Personal
              Information collected is accurate and validated using reasonable
              business practices and procedures. The Company is also committed
              to ensuring that the Personal Information remains accurate for the
              purpose for which it was collected.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Rights of Access and Correction
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              24. The Company will make reasonable efforts to ensure that
              Personal Information is at all times complete and accurate for its
              stated purpose.
              <br />
              <br />
              25. An Individual may apply for access to their Personal
              Information by submitting a request in writing along with adequate
              proof of identity to an authorized personnel officer. Where the
              application is made in person the requirement for proof of
              identity will be at the discretion of the personnel officer. The
              Individual will be provided with a copy of all available
              information that is not subject to restriction as described in
              this Privacy Policy. All Personal Information will be provided at
              no cost or at a minimal cost that is not prohibitive.
              <br />
              <br />
              26. The Company will also provide a specific summary of how the
              Personal Information has been used and to whom it has been
              disclosed. Where a detailed account of disclosure is not
              available, the Company will provide a list of organizations to
              which the Personal Information may have been disclosed.
              <br />
              <br />
              27. The Personal Information disclosed to an Individual must be in
              a form that is reasonable and understandable. Where the meaning of
              information is not clear then translations and explanations will
              be provided without additional cost.
              <br />
              <br />
              28. Where an Individual suspects that an error exists in their
              Personal Information, the Individual may submit a request in
              writing for correction. This request should include any relevant
              information substantiating the error and should describe the
              correction to be made. The Company will make all reasonable
              efforts to address any request for correction.
              <br />
              <br />
              29. Where the Individual successfully demonstrates an error in
              their Personal Information the Company will make appropriate
              corrections. Any modifications, additions or deletions to the
              Individual's Personal Information will be made only by an
              authorized personnel officer.
              <br />
              <br />
              30. Where a request for correction is not successful, the details
              and substantiating evidence of the request will be recorded and
              retained by the Company.
              <br />
              <br />
              31. The Company will endeavour to respond promptly to any
              reasonable request for disclosure and correction made by an
              Individual to ensure the continued accuracy of Personal
              Information.
              <br />
              <br />
              32. In some instances the Company may be required to limit access
              to Personal Information because of statutory or regulatory
              requirements. In all instances however the Company will make all
              reasonable efforts to comply with the Individual's request for
              access and correction to the extent of what is allowed by statute
              or regulation.
              <br />
              <br />
              33. The Company may refuse access to portions of the Personal
              Information of an Individual where it is found to contain Personal
              Information pertaining to another Individual.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Withdrawing Consent and Account Deletion
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              34. An Individual may initiate deletion of their account and their
              account record (including personal information in your account) by
              going to the “help” section in the Company app.
              <br />
              <br />
              35. An Individual may also request deletion of their Personal
              Information (including information outside their account record)
              or withdraw their consent to the Company’s collection, use and
              disclosure of their Personal Information by emailing or writing to
              the “help” section in the Company app. Please note that if you
              withdraw your consent or request to delete your account or account
              record, certain personal information may need to be retained to
              meet the Company’s legal, regulatory and contractual obligations.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Disclosure of Personal Information
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              36. The Company and the Staff will keep confidential all Personal
              Information in its control. However, the Company may transfer or
              disclose Personal Information in the following circumstances:
              <br />
              <br />
              a. to certain third parties that provide services on the Company’s
              behalf. The Company uses service providers to provide services
              such as material providers, processing payments, verifying ID,
              providing criminal background checks, and providing advertising,
              analytics and marketing services[A10] . The Company’s service
              providers are only provided with the information they need to
              perform their designated functions and are not authorized to use
              or disclose Personal Information for their own marketing or other
              purposes. The Company’s service providers may be located in the
              U.S., Canada or other foreign jurisdictions;
              <br />
              <br />
              b. with consent, to contractors or other partners, including third
              party material providers;
              <br />
              <br />
              c. where the Individual who is the subject of disclosure has
              provided consent;
              <br />
              <br />
              d. where the disclosure is in accord with the purposes for which
              the Personal Information was originally collected;
              <br />
              <br />
              e. where the disclosure is for the purpose of providing employment
              references to prospective employers and where the Personal
              Information disclosed is limited to information considered
              reasonably necessary for the purpose of providing employment
              references;
              <br />
              <br />
              f. where the Company is permitted or required to do so by
              applicable legislation or regulation;
              <br />
              <br />
              g. where the disclosure is required by authorized government
              representatives who are acting to enforce any federal, provincial
              or territorial law or carrying out an investigation relating to
              the enforcement of any federal, provincial or territorial law or
              gathering information for the purpose of enforcing any federal,
              provincial or territorial law;
              <br />
              <br />
              h. where the Company is required to comply with valid court
              orders, warrants or subpoenas or other valid legal processes
              (which may include lawful access by US or foreign courts, law
              enforcement and national security authorities) and
              <br />
              <br />
              i. in an emergency to protect the physical safety of any person or
              group of persons.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Ownership of Personal Information
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              37. All Personal Information collected by the Company in
              compliance with this Privacy Policy are business records of the
              User
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Retention and Disposal of Personal Information
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              38. The Company has Personal Information retention processes
              designed to retain Personal Information for no longer than
              necessary for the purposes for which it was collected and as set
              out in this Privacy Policy Statement, or to otherwise meet legal
              requirements.[A11]
              <br />
              <br />
              39. With respect to Staff Personal Information, [A12] any Personal
              Information collected by the Company will be retained by the
              Company during the period of active employment of the Individual
              as well as during the post-employment period only as long as the
              Personal Information is required to serve its original purpose or
              as directed by applicable legislation or regulation.
              <br />
              <br />
              40. Personal Information that is no longer needed for its stated
              purpose will be destroyed, erased or made anonymous.
              <br />
              <br />
              41. The Company will ensure that all practices and procedures
              relating to the disposal of Personal Information will respect the
              fundamental policy of confidentiality. All Personal Information
              disposal procedures, including the disposal of computerized data
              storage devices, will ensure the complete destruction of Personal
              Information so that there will be no risk of subsequent
              unauthorized disclosure of Personal Information.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Deceased Individuals
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              42. The rights and protections of the Company's Privacy Policies
              will extend to deceased Individuals.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Security
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              43. The Company will take and enforce all reasonable security
              measures appropriate for the sensitivity of the information to
              ensure that all Personal Information for every Individual is
              protected against any form of unauthorized use including but not
              limited to accidental or malicious disclosure, unauthorized
              access, unauthorized modification, unauthorized duplication or
              theft.
              <br />
              <br />
              44. Methods of security will include but not be limited to the
              following:
              <br />
              <br />
              a. physical security including locked filing cabinets and
              secure-access offices;
              <br />
              <br />
              b. organizational security including security clearances and
              access limited on a “need-to-know” basis and
              <br />
              <br />
              c. technological security including passwords and encryption.
              <br />
              <br />
              45. The Company will educate and inform all Staff regarding the
              Privacy Policy and related procedures and on the importance of
              confidentiality of Personal Information and will monitor
              compliance with the Privacy Policy and may observe and investigate
              the information management practices of all Staff having care of
              Personal Information
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Knowledge of Unauthorized Disclosure
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              46. Responsibility for the security of Personal Information is a
              responsibility that the Company holds in very serious regard. Any
              Staff having knowledge of an impending unauthorized disclosure,
              whether intentional or unintentional, and who fail to act to
              prevent the unauthorized breach will be subject to sanction as
              described in the Enforcement section of this document including
              the immediate dismissal of the offending Staff.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Enforcement
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              47. All Staff having care over Personal Information must comply
              with the policies, procedures and practices described in the
              Privacy Policy. Any breach of any term or condition of this
              Privacy Policy, whether intentional or unintentional, including
              but not limited to the unauthorized disclosure of Personal
              Information is grounds for disciplinary action up to and including
              the immediate dismissal of any and all responsible Staff. Any
              breach of any term or condition of this Privacy Policy, whether
              intentional or unintentional, is grounds for dismissal with cause.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Compliance with Privacy Policy
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              48. The Company will have a procedure that will allow Individuals
              to challenge the Company's compliance with this Privacy Policy.
              The Company will also have procedures to promptly respond to
              Privacy Policy compliance challenges.
              <br />
              <br />
              49. The Company will make all reasonable efforts to investigate
              and respond to compliance challenges relating to this Privacy
              Policy. Where a challenge is well founded the Company will take
              action to correct any outstanding problems up to and including
              amending the Privacy Policy and related procedures.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Arbitration
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              Arbitration 50. In the event a dispute arises out of or in
              connection with this Privacy Policy, the parties will first
              attempt to resolve the dispute through friendly consultation. If
              the dispute is not resolved within a reasonable period then any or
              all outstanding issues may be submitted to final and binding
              arbitration in accordance with the laws of the respected Province.
              The arbitrator's award will be final, and judgment may be entered
              upon it by any court having jurisdiction within the Province.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-8 text-left font-Bold"
          >
            Contact Us
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-8 text-left font-normal"
            >
              Contact Us If an Individual has any questions or comments about
              this Privacy Policy Statement or the manner in which the Company
              or its service providers (including its service providers outside
              Canada) treat the Individual’s Personal Information, or to request
              access to, or correction or deletion of, the Individual’s Personal
              Information, the Individual or company may contact Provisorr Inc.
              at: support@Provisorr.com
            </Typography>
          </Typography>
        </div>
      </div>
    </div>
  );
};
