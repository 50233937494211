import React from "react";

export const Button = (props: any) => {
  return (
    <button
      className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-red-300 focus:outline-none focus:bg-red-500"
      type={props.type}
    >
      {props.children}
    </button>
  );
};
