/** node library
 */
import { Dialog, DialogBody } from "@material-tailwind/react";

/** custom library
 */

/** start
 */

const MessageModal = (props) => {
  const { windowSize } = props;
  const screenWidth = windowSize[0];
  const dialogBox =
    screenWidth > 540 ? (
      <Dialog
        className="w-full"
        open={props.modalState}
        handler={props.onClose}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}>
        {/* <DialogHeader className="flex items-start justify-between">
          <>{props.title}</>
          <Button
            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={props.onClose}
          >
            <span className=" text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
              ×
            </span>
          </Button>
        </DialogHeader> */}
        <DialogBody
          divider
          className=" flex items-center justify-center rounded-lg">
          <p className=" py-12 items-center text-base lg:text-xl font-bold">
            {props.Message}
          </p>
        </DialogBody>
        {/* <DialogFooter></DialogFooter> */}
      </Dialog>
    ) : (
      <Dialog
        size="xl"
        className="w-full "
        open={props.modalState}
        handler={props.onClose}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}>
        <DialogBody
          divider
          className=" flex items-center justify-center rounded-lg">
          <p className=" p-12 items-center text-base lg:text-xl font-bold">
            {props.Message}
          </p>
        </DialogBody>
        {/* <DialogFooter></DialogFooter> */}
      </Dialog>
    );
  return <>{dialogBox}</>;
};

export default MessageModal;
