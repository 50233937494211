/** node library
 */
import { FC, useRef } from "react";

/** custom library
 */
import { Home } from "./Home";
import NavigationBar from "../layout/components/NavigationBar";

/** start
 */

const Landing: FC = () => {
  const refHome = useRef<null | HTMLDivElement>(null);
  const refWorks = useRef<null | HTMLDivElement>(null);
  const refBenefits = useRef<null | HTMLDivElement>(null);
  const refPBenefits = useRef<null | HTMLDivElement>(null);
  const refADownload = useRef<null | HTMLDivElement>(null);
  const refContact = useRef<null | HTMLDivElement>(null);

  const refAppNav = useRef<null | HTMLDivElement>(null);

  const handleClickHome = () => {
    refHome.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickWork = () => {
    refWorks.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickBenefits = () => {
    refBenefits.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickPBenefits = () => {
    refPBenefits.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickADownload = () => {
    refADownload.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleClickContact = () => {
    refContact.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickAppNav = () => {
    refAppNav.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <header className="w-full fixed z-30">
        <NavigationBar
          homeRefClick={handleClickHome}
          workRefClick={handleClickWork}
          benefitRefClick={handleClickBenefits}
          pbenefitRefClick={handleClickPBenefits}
          adownloadRefClick={handleClickADownload}
          contactRefClick={handleClickContact}
          AppNavRefClick={handleClickAppNav}
        />
      </header>

      <main ref={refHome} className="pt-0 z-20 relative">
        <Home
          refWorks={refWorks}
          refBenefits={refBenefits}
          refPBenefits={refPBenefits}
          refADownload={refADownload}
          refContact={refContact}
          refAppNav={refAppNav}
          AppNavRefClick={handleClickAppNav}
          contactRefClick={handleClickContact}
        />
      </main>

      {/* <footer className="bg-footer relative px-4 pt-8 pb-6">
        <Footer />
      </footer> */}
    </>
  );
};

export default Landing;
