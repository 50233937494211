/** node library
 */
import { Chip } from "@material-tailwind/react";
import { OptionIcon, UserDetailsChip } from "./utility";

/** start
 */

export const user_column_value = [
  {
    data: "id",
    name: "ID",
    selector: (row: any) => row.userId,
    sortable: true,
    minWidth: "1rem",
    omit: true,
  },
  {
    data: "username",
    name: "User",
    selector: (row: any) => <UserDetailsChip row={row} />,
    sortable: true,
    minWidth: "16rem",
  },
  {
    data: "email",
    name: "Email",
    selector: (row: any) => row.email,
    sortable: true,
    minWidth: "16rem",
  },
  {
    data: "role",
    name: "Role",
    selector: (row: any) => row.role,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "phone",
    name: "Phone",
    selector: (row: any) => row.phone,
    sortable: true,
    minWidth: "16rem",
  },
  {
    data: "active",
    name: "Status",
    selector: (row: any) => (
      <Chip
        variant="filled"
        color={row.active === true ? "blue" : "amber"}
        value={row.active === true ? "active" : "in-active"}
      />
    ),
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "id",
    name: "Action",
    selector: (row: any) => <OptionIcon />,
    sortable: true,
    minWidth: "8rem",
  },
];
