import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
  `;

const Spinner = styled.div`
  margin: 1rem;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 0.125rem solid grey;
  border-right: 0.125rem solid grey;
  border-bottom: 0.125rem solid grey;
  border-left: 0.25rem solid black;
  background: transparent;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
`;

export const RotateSpinner = () => (
  <div style={{ padding: "1.5rem" }}>
    <Spinner />
  </div>
);
