/** node library
 */
import axios from "axios";

/** custom library
 */
import { AuthenticationModel } from "../../authentication/data/index";
import * as url_config from "../URLs";

/** start
 */

export function login(username: string, password: string) {
  return axios.post<AuthenticationModel>(url_config.default.signing, {
    emailOrPhone: username,
    password,
    deviceUniqueId:"temp"
  });
}
