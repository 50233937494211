import { useEffect, useState } from "react";
import TextTransition, { presets } from "react-text-transition";

const TEXTS = ["Customers", "Pros"];
const TextCarousel = () => {
	const [index, setIndex] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(
			() => setIndex((index) => index + 1),
			2000 // every 3 seconds
		);
		return () => clearTimeout(intervalId);
	}, []);

	return (
		<TextTransition springConfig={presets.gentle}>
			{TEXTS[index % TEXTS.length]}
		</TextTransition>
	);
};

export default TextCarousel;
