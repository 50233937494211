/** node library
 */
import {
  Chip,
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

/** start
 */
const OptionMenu = (props) => {
  return (
    <div className="flex flex-row justify-center gap-2 divide-x-2">
      <i className="fa-solid fa-trash cursor-pointer"></i>
      <i className="fa-solid fa-pen cursor-pointer pl-2"></i>
    </div>
  );
};

export const OptionIcon = () => {
  const option_logo =
    require("../../../../asset/image/admin_icon/option.svg").default;

  return (
    <>
      <Popover placement="right">
        <PopoverHandler>
          <img className="" src={option_logo} alt=""></img>
        </PopoverHandler>
        <PopoverContent>
          <OptionMenu />
        </PopoverContent>
      </Popover>
    </>
  );
};

export const UserDetailsChip = (props) => {
  const { row } = props;
  const navigate = useNavigate();
  return (
    <Chip
      color="teal"
      variant="filled"
      className="rounded-full"
      value={row.username}
      onTap={() =>
        navigate("/admin/user/details", {
          state: {
            id: row.userId,
          },
        })
      }
    />
  );
};
