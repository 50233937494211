/** node library
 */

/** custom library
 */
import * as url from "../../payloads/URLs";
import { column_value } from "./data/OrderColumn";
import { SimpleTable } from "../../components/datatable/SimpleTable";

/** start
 */

export const OrderList = () => {
  return (
    <>
      <div className="h-full w-full">
        <div className="bg-white m-4 py-4 rounded-lg hidden">
          <form className="px-8">
            <div className="grid gap-4 mb-4 md:grid-cols-3">
              <div>
                <label
                  htmlFor="feedback_date"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Search Date
                </label>
                <input
                  type="text"
                  id="feedback_date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="feedback_category"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Category
                </label>
                <input
                  type="text"
                  id="feedback_category"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="feedback_status"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Status
                </label>
                <input
                  type="text"
                  id="feedback_status"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required
                />
              </div>
            </div>
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Search
            </button>
          </form>
        </div>

        <div className="h-full w-full p-4 rounded-lg">
          <SimpleTable
            url={url.default.orderTable}
            title={OrderList.name}
            columns={column_value}
          ></SimpleTable>
        </div>
      </div>
    </>
  );
};
