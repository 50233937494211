import PropTypes from "prop-types";

const Benefit = ({ windowSize, alignment, icon, title, description }) => {
	const renderContent =
		windowSize > 540 && alignment === "left" ? (
			<div className="flex flex-row justify-end">
				<div className="w-5/6 px-4 flex items-center justify-end">
					<h4 className="text-xl lg:text-2xl font-bold text-end">
						{title}
					</h4>
				</div>
				<div className="bg-white border-home-icon_border border-2 m-auto p-4 rounded-lg w-1/6">
					<img src={icon} alt="" className="h-full w-full" />
				</div>
			</div>
		) : (
			<div className="flex pb-4">
				<div className="bg-white border-home-icon_border border-2 m-auto p-4 rounded-lg w-1/6">
					<img src={icon} alt="" className="h-full w-full" />
				</div>
				<div className="w-5/6 px-4 flex items-center">
					<h4 className="text-start text-xl lg:text-2xl font-bold">
						{title}
					</h4>
				</div>
			</div>
		);

	return <div className="p-4">{renderContent}</div>;
};

Benefit.propTypes = {
	windowSize: PropTypes.number.isRequired,
	icon: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	alignment: PropTypes.oneOf(["left", "right"]),
};

const BenefitSection = ({ windowSize, benefits, alignment }) => (
	<div className={`my-auto ${alignment === "right" ? "pl-4" : "pr-4"}`}>
		{benefits.map((benefit, index) => (
			<div key={index + 1}>
				<Benefit
					windowSize={windowSize[0]}
					alignment={alignment}
					{...benefit}
				/>
			</div>
		))}
	</div>
);

BenefitSection.propTypes = {
	windowSize: PropTypes.array.isRequired,
	benefits: PropTypes.array.isRequired,
	alignment: PropTypes.oneOf(["left", "right"]),
};

export default BenefitSection;
