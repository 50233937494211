/** node library
 */
import { Link,  } from "react-router-dom";
import { Typography } from "@material-tailwind/react";

/** custom library
 */
import logo from "../../../asset/image/logo.svg";

/** start
 */

export const TermsCondition = () => {
  return (
    <div className="h-full w-full">
      <div className="h-full w-full p-4 rounded-lg">
        <div className="flex w-full m-auto items-center justify-center p-8">
          <Link to="/" className="">
            <img className="inline-block h-12 w-50" src={logo} alt=""></img>
          </Link>
        </div>
        <div className="p-8 items-center justify-center">
          <Typography variant="h4" color="black" className="mb-6 text-center">
            TERMS OF USE
          </Typography>
        </div>
        <div className="container items-center justify-center m-auto bg-white p-8 rounded-xl">
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-bold"
          >
            Last revision: March 23rd , 2023
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            The following terms of use (the “Terms of Use”) govern your access
            to and use of services we make available on provisorr.com or
            provisorr.ca or provisorr app in google play store or apple app
            store for both “customers” (Local residents seeking for pros or
            contractors) or “Pros” (local contractors seeking for jobs) on the
            “Provisorr Platform”.
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            These Terms of Use form an agreement between Provisorr, Inc.
            (“Provisorr”, “us”, “we”, “our”) and you. The term “you” or “User”
            refers to the person or entity browsing, accessing or otherwise
            using the Provisorr Platform (“use” or “using” in these Terms of Use
            will mean any of the foregoing).
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            BY USING THE PROVISORR PLATFORM, YOU: (A) REPRESENT AND WARRANT THAT
            (I) YOU HAVE REACHED THE AGE OF MAJORITY IN YOUR JURISDICTION, (II)
            YOU HAVE THE CAPACITY TO ENTER INTO BINDING OBLIGATIONS, AND (III)
            ALL INFORMATION SUPPLIED BY YOU TO US THROUGH THE PROVISORR PLATFORM
            IS TRUE, ACCURATE, CURRENT, AND COMPLETE; AND (B) AGREE TO BE BOUND
            BY AND COMPLY WITH THESE TERMS OF USE, AS UPDATED FROM TIME TO TIME
            IN ACCORDANCE WITH SECTION 1.
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            IF YOU ARE USING THE PROVISORR PLATFORM ON BEHALF OF ANOTHER PERSON
            OR A CORPORATE ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE
            AUTHORITY TO BIND SUCH PERSON OR ENTITY TO THESE TERMS OF USE.
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            This Agreement does not alter in any way the terms or conditions of
            any other agreement you may have with us in respect of any products,
            applications, services or otherwise.
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 mt-8 text-left font-normal"
          >
            1. PROVISORR Platform
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            (a) Our Role. The Provisorr Platform is a marketplace where Users as
            well as Local residents (“customers”) seeking for different kind of
            services such as home improvement, automobiles and other basic day
            to day services and local professionals (“Pros” or “Service
            provider”) may enter into agreements for the provision of the Pro’s
            services (such an agreement can be described as a “contract”).
            Through the Provisorr Platform, Customers may request Services as
            well as post a “problem”, “project” or “task” or “job” and Pros may
            bid to provide such services. Customers may see multiple bids, chat
            with them, negotiate date and time and place an order whoever they
            want and after placing an order the customer and that specific Pro
            who got the order are in an contract for the provision of the
            Services. Provisorr may engage third parties to assist it in
            providing the Provisorr Platform or any part thereof.
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            (b) Provisorr not a Party to the Pros. You understand that Provisorr
            is not a party to the Contracts. At all times, Customers and Pros
            are responsible for evaluating Contracts and the other Customers and
            pros with whom they choose to enter into such Contracts. Provisorr
            may review and accept or reject proposed Contracts in its sole
            discretion, but Provisorr does not supervise and is not responsible
            for the content or fulfilment of any Contracts.
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            (c) Closing out a Contract.
            <Typography
              variant="h6"
              color="black"
              className="mb-4 pl-12 text-left font-normal"
            >
              (i) When a Pro fulfills a Contract, Pro will require to go through
              the completion process with a OTP(one time password) verification.
              The customer is responsible to provide the OTP. Before sharing the
              OTP, it is customers’ responsibility to check the completion of
              the work done by the pro. Once the OTP is shared and the Pro
              verifies the OTP, the job is considered as completed on the
              Provisorr platform. Provisorr doesn’t play any role or force any
              parties for the completion of the work. Customers shouldn’t share
              the OTP until the job is completed. The service provider or pro is
              responsible for taking the fees in person in cash, check, card
              reader or online from the customer before verifying with OTP for
              order completion as for now the payment is not processed on the
              Provisorr platform. So Provisorr is not responsible for any of the
              payment provided by the customers to the pros. The customers are
              also responsible for paying the fees to the service providers as
              Provisorr doesn’t process any payment through the platform between
              customers and Pros. The terms of use might change if Provisorr
              platform process the payment in future. If the work is partially
              completed then the customer and Pro can notify the Provisorr
              platform through help option but Provisorr platform doesn’t
              guarantee any payment or completion of the work.
            </Typography>
            <Typography
              variant="h6"
              color="black"
              className="mb-4 pl-12 text-left font-normal"
            >
              (ii) You acknowledge that in no event, is Provisorr required to
              facilitate or arbitrate any disputes among Customers and Pros.
              Users who have entered into a Contract are encouraged to resolve
              disputes among themselves and come to an agreement with respect to
              any cancellations or refunds. But Both parties (customer and Pro )
              can file a claim regarding an order through the help option or
              email to the support@provisorr.com. But Provisorr platform doesn’t
              guarantee any payment or completion of the work or put both
              parties to an agreement to resolve the matter.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            (d) Provisorr Pro. Provisorr is an open and free platform to list
            your profile as well as your service store for free and also get
            connected with the other local service providers in your local area
            to get help from each other. But for early access in bidding,
            getting new orders from new and existing customers and growing your
            business you must have to have Provisorr Pro. For now, testing the
            MVP all the service providers looking for a job is considered as
            Pro. When the subscription will start Provisorr platform will notify
            the Pros regarding that and you(service provider) may or may not
            become a Pro member for a small fees per month. Pro members will
            have a lot of extra features and facilities over the basic free
            users. The pro membership can be cancelled anytime but at least
            require to notify 1 month earlier. The paid subscription fees cannot
            be returned from the platform.
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-4 pl-12 text-left font-normal"
            >
              (i) All the services, offerings, features and functionality of the
              standard Provisorr Platform;
              <br />
              (ii) The pro subscribers are able to access bids earlier than
              other which facilitates to get the job. They are able to have
              their own store and list their own micro services with or without
              rates. <br />
              (iii) The Pro will have unlimited visit on their store or Profile
              <br />
              (iv) Pro can see the bid statistics (how many have bid and lowest,
              highest and average amount of bids) of that specific project and
              start chatting with customers before they choose them to
              communicate for a small fee.
              <br />
              (v) The Pro will be able to bid and communicate with the customers
              for free.
              <br />
              (vi) Pro can have their own advanced statistics of revenues of
              each of their services
              <br />
              (vii) Pro will get a verified badge (with Provisorr verification)
              and Pro Badge and if the pro gets 5 stars in last 3 projects then
              they will get a 5 star badge
              <br />
              (viii) Pro can offer coupons for a small fee to attract customers
              <br />
              (ix) Any other services, offerings, features and functionality as
              may be determined and implemented in Provisorr’s sole discretion
              from time to time.
            </Typography>
            Any reference to the Provisorr Platform throughout these Terms of
            Use includes Provisorr Pro, unless otherwise stated.
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            (e) Your Responsibilities.
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-4 pl-12 text-left font-normal"
            >
              (i) Customers are solely responsible for determining the
              suitability of Contractors to provide the requested specific
              Services and for paying for the that Services. Provisorr hereby
              disclaims all liability for any claims arising from, in respect
              of, or relating to the suitability of a Pro to provide the
              requested Contractor Services. Customers will not use or access
              the Provisorr Platform or request Services: (A) in violation of
              any applicable law or a third party’s rights; (B) in a manner that
              threatens the security or functionality of the Provisorr Platform;
              or (C) in any manner not expressly permitted in these Terms of
              Use; or (D) submit or request any Services that would require
              action or inaction by Pros or any third party that would violate
              any applicable law or a third party’s rights.
              <br />
              <br />
              (ii) Pros are solely responsible for (A) determining which
              requests for their Services to bid on; (B) which Contracts to
              enter into; (C) providing the specific or Contractor Services; and
              (D) ensuring that Contracts and Contractor Services comply with
              all applicable laws, licenses, including applicable building code
              and consumer protection laws. Contractors will not use or access
              the Provisorr Platform or enter into Contracts: (1) in violation
              of any applicable law or a third party’s rights; (2) in a manner
              that threatens the security or functionality of the Provisorr
              Platform; or (3) in any manner not expressly permitted in these
              Terms of Use.
              <br />
              <br />
              (iii) By using the Provisorr Platform, Customers grants Provisorr
              the right without being guilty of trespass to place a
              Provisorr-branded road sign (the “Road Sign”) on the Customer’s
              property in such a manner that the Road Sign is clearly visible to
              passersby. The Road Sign may be placed on the Customer’s property
              by Provisorr at any time between when the Customer requests
              Contactor Services and five days following when the Contractor
              Services are marked complete. Customer agrees to keep the Road
              Sign in the same position on the property as initially placed by
              Provisorr for four days following Provisorr’s initial placement of
              the Road Sign (the “Minimum Promotional Period”). At any time
              following the Minimum Promotional Period, Customer may remove the
              Road Sign from their property and dispose of the Road Sign at
              their discretion.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            f) Restrictions on Use.
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-4 pl-12 text-left font-normal"
            >
              (i) Prohibited Uses. You will not, nor will you permit others to:
              (i) use the Provisorr Platform to resell or permit timesharing or
              service bureau use of the Provisorr Platform; (ii) knowingly
              submit or post any User Feedback (as defined below) that is untrue
              or inaccurate; (iii) use the Provisorr Platform to create,
              collect, transmit, store, use or process any User Data: (A) that
              contains any computer viruses, worms, malicious code, or any
              software intended to damage or alter a computer system or data;
              (B) that you do not have the lawful right to create, collect,
              transmit, store, use or process; (C) that violates any applicable
              laws, or infringes, violates or otherwise misappropriates the
              intellectual property or other rights of any third party
              (including any moral right, privacy right or right of publicity);
              (iv) reverse engineer, de-compile or disassemble any component of
              the Provisorr Platform; (vi) remove or obscure any proprietary
              notices or labels on the Provisorr Platform ; (vii) access or use
              the Provisorr Platform for the purpose of building a similar or
              competitive product or service; or (viii) perform any
              vulnerability, penetration or similar testing of the Provisorr
              Platform.
              <br />
              <br />
              (ii) Communications through Provisorr Platform. You agree to use
              the communication services available on the Provisorr Platform to
              communicate with other Users prior to entering into a Contract.
              You agree that before entering into a Contract with a Homeowner or
              Contractor, you (a) will only use the Provisorr Platform to
              communicate with other Users; (b) will not provide your
              information that would allow another person to contact you
              directly (“Direct Contact Information”) to any other User or
              another person that you identified or were identified by through
              the Provisorr Platform; (c) will not use Direct Contact
              information of another user to attempt to or to communicate with,
              solicit, contact, or find the contact information of a User
              outside of the Provisorr Platform; (d) will not ask for, provide,
              or attempt to identify through public means the contact
              information of another User; and (e) you will not include any
              Direct Contact Information or means by which your contact
              information could be discovered in any request or bid for
              Contractor Services, except as otherwise provided on the Provisorr
              Platform. You acknowledge and agree that a violation of any
              provision of this Section 1(e)(ii) is a material breach of the
              Terms of Service.
              <br />
              <br />
              Verifications and Certifications.
              <br />
              <br />
              (i) Provisorr doesn’t verify any kind of License or certification
              verification provided by the Pro on their profile (“by uploading a
              photo of the certification”) as it is considered as an open and
              free platform to join and communicate. Background Verification.
              For a fee, Contractors may elect and consent to undergo an
              optional annual background check and ID verification process
              whereby third parties will confirm the Contractor’s identity (“ID
              Verification”) and search all records and information available on
              the Contractor at the time the search is conducted including
              non-conviction information, charges before the courts, findings of
              guilt or convictions and court orders registered in the
              Contractor’s name in the National Repository of Criminal Records,
              the Canadian Police Information Centre database and local records
              available to the police service (the “Background Check” and
              together with the ID Verification, “Background Verification”). The
              Background Check may also include a search of court records and a
              query of records management systems in other police agencies'
              jurisdictions through the Police Information Portal or other data
              sharing systems. Contractors that have their identify confirmed
              through the ID Verification process and/or that undergo a
              Background Check that indicates the Contractor is not subject to
              any charges before the courts, findings of guilt or convictions
              and court orders registered in the Contractor’s name in the
              National Repository of Criminal Records, the Canadian Police
              Information Centre database and local records available to the
              police service, court records, records management systems in other
              police agencies' jurisdictions through the Police Information
              Portal or other data sharing systems (a “Verified Background”),
              may receive a notation on their Provisorr profile indicating such
              Verified Background. Contractors that have a Verified Background
              are permitted to request a site visit at the location where a
              Homeowner has requested the Contractor Services be provided prior
              to the provision of the Contractor Services (a “Site Visit”).
              Provisorr may, in its sole discretion, limit the number of Site
              Visit requests a Contractor is able to make. Provisorr does not
              represent or warrant that (A) the Background Verification will be
              complete and accurate, or (B) Contractor Services completed by a
              Contractor that has a Verified Background will be of a certain
              quality or meet a certain standard. Provisorr hereby disclaims all
              liability for any claims arising from, in connection with, or
              relating to (1) the accuracy and completion of the Background
              Verification, and (2) the quality of Contractor Services performed
              by a Contractor that has a Verified Background.
              <br />
              <br />
              (ii) Certifications. For a fee, Contractors may elect to
              participate in additional verification and certification programs
              whereby Provisorr or third parties will review the Contractor’s
              background information and credentials against certain
              pre-determined criteria to assess whether the Contractor meets
              such criteria (collectively, “Provisorr Certifications”).
              Contractor’s that successfully undergo a Provisorr Certification
              will have a notation indicating such certification displayed on
              their Provisorr Profile. Whether a Contractor receives a Provisorr
              Certification is at the discretion of Provisorr or the third party
              conducting the Provisorr Certification. Provisorr does not
              represent or warrant that Contractor Services completed by a
              Contractor with a Provisorr Certification will be of a certain
              quality or standard. Provisorr hereby disclaims all liability for
              any claims arising from, in connection with, or relating to the
              quality of Contractor Services performed by a Contractor with a
              Provisorr Certification.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            (h) User Ratings.
            <Typography
              variant="h6"
              color="black"
              className="mb-4 mt-4 text-left font-normal"
            >
              (i) You acknowledge and agree that: (A) Users may submit feedback
              and ratings about other Users’ content and services (“User
              Feedback”) and such User Feedback will be used to establish each
              User’s “Platform Rating” associated with their Account (as defined
              in Section 2(a)); (B) Platform Ratings do not reflect the opinion
              of Provisorr; (C) Provisorr will make Platform Ratings visible to
              other Users; (D) Provisorr is not responsible for and does not
              verify User Feedback for accuracy or completeness; (E) Pro will
              get review or rating for completing every single task (No one is
              able to put any review or rating if the task is not completed or
              cancelled by Pro or customer)
              <br />
              (ii) You agree not to submit User Feedback that is: (A) false,
              misleading, or impersonates any other person; (B) is bullying,
              harassing, abusive, threatening, vulgar, obscene, or offensive, or
              that contains pornography, nudity, violence, or that promotes
              violence, racism, discrimination, bigotry, hatred, or physical
              harm of any kind against any group or individual.
              <br />
              (i) Suspension of Access; Scheduled Downtime; Modifications.
              Provisorr may from time to time and in its discretion, without
              limiting any of its other rights or remedies at law or in equity,
              under these Terms of Use: (i) suspend your access to or use of the
              Provisorr Platform: (A) for scheduled maintenance; (B) due to a
              Force Majeure (defined below); (C) if Provisorr believes in good
              faith that you have violated any provision of these Terms of Use;
              (D) if you have received a poor Platform Rating; (E) to address
              any emergency security concerns; or (F) if required to do so by a
              regulatory body or as a result of a change in applicable law; and
              (ii) to the fullest extent permitted by applicable laws, make any
              Modifications to the Provisorr Platform.
            </Typography>
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            <br />
            2. User Accounts
            <br />
            <br />
            (a) User Accounts. To access certain features of the Provisorr
            Platform, you must register for a Provisorr Platform account
            (“Account”). Provisorr may reject Account registrations in its sole
            discretion. You are solely responsible for all actions made on your
            Account and for keeping your login credentials safe and private. You
            agree not to share your login credentials with anyone else or use
            another User’s Account to access the Provisorr Platform. You must
            promptly notify us of any actual or suspected unauthorized use of
            your Account.
            <br />
            (b) Users Authorized to Act. If you are requesting an Account on
            behalf of another person or entity, you represent and warrant that
            you are permitted by such person or entity to use the Provisorr
            Platform on such person or entity’s behalf, including by requesting
            Contractor Services, posting bids, fulfilling Contracts, evaluating
            and selecting Contractors to provide Contractor Services, and paying
            for Contractor Services.
            <br />
            <br />
            3. Electronic Communications
            <br />
            <br />
            (a) When you use or view the Provisorr Platform or send e-mails,
            texts or other electronic messages to us, you are communicating with
            us electronically and you consent to receive communications from us
            electronically. We will communicate with you by e-mail or by posting
            notices on the Provisorr Platform. You agree that all agreements,
            notices, disclosures and other communications that we provide to you
            electronically satisfy any legal requirement that such
            communications be in writing.
            <br />
            (b) By creating an account to access the Provisorr Platform, you
            acknowledge and agree that Provisorr will send you service-related
            e-mails relating to your account, including service updates. These
            communications can be managed through user features made available
            through the Provisorr Platform from time to time.
            <br />
            (c) If you are a member of our mailing list you will also receive
            email communications from us regarding our products, services and
            initiatives (including collaborations and partnerships). If you do
            not wish to receive these communications, you can unsubscribe from
            such promotional e-mails at any time by clicking on the unsubscribe
            link in any of our e-mail communications.
            <br />
            (d) If you sign up through Provisorr to receive special offers
            regarding products and services from our partners, you authorize us
            to share your email address and other Personal Information you
            authorize with the partner whose offer you wish to receive. If you
            request these special offers, you acknowledge the partner may also
            send you future offers that may interest you. You can opt out of
            future communications from Provisorr by changing your user settings
            or emailing support@Provisorr.com; you can unsubscribe from future
            partner communications by following their unsubscribe and opt out
            instructions. You do not have to agree to participate in these
            offers in order to use the Provisorr Platform. You understand that
            Provisorr may receive financial remuneration in exchange for sharing
            your email address and other Personal Information you authorize with
            partners if you choose to sign up for an offer. Once you sign up for
            an offer, you can change your mind at any time, but if the partner
            or Provisorr has already relied on your authorization to share your
            email or other Personal Information for a particular offer, any
            action already taken cannot be undone.
            <br />
            <br />
            4. Data; Intellectual Property
            <br />
            <br />
            (a) Except as expressly set forth in these Terms of Use, nothing in
            these Terms of Use assigns or grants to Provisorr any right, title
            or interest, including any intellectual property rights, in or to
            your User Data. You grant to Provisorr a nonexclusive, worldwide,
            royalty-free, irrevocable, fully paid-up right to access, use,
            process, store, collect, disclose, and transmit User Data to: (i)
            provide the Provisorr Platform; (ii) improve and enhance the
            Provisorr Platform and for other Provisorr offerings; and (iii)
            produce data, information or other materials that are not identified
            as relating to a particular individual or Account (such data,
            information and materials, the “Aggregated Data”). Provisorr may use
            Aggregated Data for any purpose and without restriction or
            obligation to you.
            <br />
            Provisorr or its licensors retain all ownership and intellectual
            property rights in and to: (i) the Provisorr Platform; (ii) anything
            developed or delivered by or on behalf of Provisorr under these
            Terms of Use, including the Domain Name, if applicable; and (iii)
            any Modifications to the foregoing (i) and (ii).
            <br />
            (b) You may request that we delete your Account or Account record
            (including personal information in your Account) as set out in
            Provisorr’s privacy policy located at
            https://www.Provisorr.com/privacy-policy. If you have requested that
            we delete your Account, you must cease using the Provisorr Platform
            immediately.
          </Typography>
          <Typography
            variant="h6"
            color="black"
            className="mb-4 text-left font-normal"
          >
            <br />
            5. Privacy <br />
            <br />
            (a) You understand that your Personal Information will be treated in
            accordance with Provisorr’s privacy policy located at
            https://www.Provisorr.com/privacy-policy. (b) Personal Information
            Warranty. You represent and warrant to, and covenant with Provisorr
            that your User Data will only contain Personal Information in
            respect of which you have provided all notices and disclosures,
            obtained all applicable third party consents and permissions and
            otherwise have all authority, in each case as required by applicable
            laws, to enable Provisorr to provide the Provisorr Platform,
            including with respect to the collection, storage, access, use,
            disclosure, processing and transmission of Personal Information,
            including by or to Provisorr and to or from all applicable third
            parties.
            <br />
            <br />
            6. Support
            <br />
            <br />
            User will generally have access to Provisorr’s technical support
            services (“Support Services”) from 10:00 am to 4:00 pm on
            Monday-Friday, through email at support@Provisorr.com, or by using
            help option in the Provisorr app.
            <br />
            <br />
            7. Fees and Payment You must pay the following fees, as applicable
            to you, in accordance with these Terms of Use:
            <br />
            (a) Provisorr Pro Fees. Requesting Contractor Services, submitting
            bids and using the Provisorr Platform is free unless otherwise
            stated herein. Notwithstanding the foregoing, Pros that elect to
            participate in and sign up for Provisorr Pro must pay the monthly or
            annual subscription fee as set out in the payment schedule on the
            Provisorr Platform (“Provisorr Pro Fees”). Pros will pay the initial
            Provisorr Pro Fees to Provisorr through the Provisorr Platform and
            will be automatically charged the Provisorr Pro Fees on a monthly or
            annual basis, as the case may be, in advance of the first
            Subscription Period. Provisorr may or may not offer Contractors
            promotional discounts for Provisorr Pro for a period determined by
            Provisorr in its sole discretion (the “Promotional Period”). After
            the Promotional Period, Contractors will be automatically charged
            the Provisorr Pro Fees on a monthly basis. Contractors will pay the
            Provisorr Pro Fees for the forthcoming Subscription Period in
            advance of the Subscription Period. Contractors may cancel Provisorr
            Pro at any time prior to commencement of the next annual
            Subscription Period by contacting Provisorr teams through the
            support channels..
            <br />
            (b) Background Verification Fees. Unless otherwise set out herein,
            if a Contractor elects to undergo ID Verification and/or a
            Background Check, the Contractor must be a Pro member and pay an
            additional fee for the verification. Pros will pay their initial ID
            Verification Fee and/or Background Check Fee in advance to Provisorr
            through the Provisorr Platform. If a Contractor elects to undergo an
            ID Verification and/or Background Check, the Contractor will
            automatically be subject to an ID Verification and/or Background
            Check on an annual basis and will be automatically charged the
            annual ID Verification Fee and/or Background Check Fee accordingly,
            unless the Contractor cancels the upcoming Background Check and/or
            ID Verification at least 30 days’ prior to the date the subsequent
            Background Check Fee and/or ID Verification Fee is set to be charged
            to the Contractor, as set out in your Account.[A8] <br />
            (c) Certification Fees. Contractors may be required to pay
            additional fees to Provisorr or a third party for Provisorr
            Certifications, as may be published on the Provisorr Platform from
            time to time (the “Certification Fees”, together with the Contract
            Fees, Provisorr Pro Fees, ID Verification Fee and Background Check
            Fee, the “Fees”). Contractors will pay Certification Fees in advance
            to (i) Provisorr through the Provisorr Platform, or (ii) to the
            applicable third party providing the Provisorr Certification, using
            the payment method determined by such third party.
            <br />
            (d) Payment Methods. Pro must provide a valid method of payment upon
            the earlier of: (i) electing to participate in and signing up for
            Provisorr Pro; (ii) electing to participate in a ID/Background
            Verification; or (iii) electing to participate in a Provisorr
            Certification. You agree and acknowledge that we may bill your
            payment method for the Fees in accordance with these Terms of Use
            and any applicable Contracts.
            <br />
            (e) Taxes. The Fees set out in these Terms of Use do not include
            applicable sales, use, gross receipts, value-added, excise, personal
            property or other taxes. Users will be responsible for and pay all
            applicable taxes, duties, tariffs, assessments, export and import
            fees or similar charges (including interest and penalties imposed
            thereon) on the transactions contemplated in connection with these
            Terms of Use other than taxes based on the net income or profits of
            Provisorr.
            <br />
            (f) Suspension. Any suspension of the Provisorr Platform by
            Provisorr pursuant to the terms of these Terms of Use will not
            excuse Users from their obligation to make payments under these
            Terms of Use.
            <br />
            <br />
            8. Confidential Information
            <br />
            <br />
            (a) Definitions. For the purposes of these Terms of Use, a party
            receiving Confidential Information (as defined below) will be the
            “Recipient”, the party disclosing such information will be the
            “Discloser” and “Confidential Information” of Discloser means any
            and all information of Discloser or any of its licensors that has or
            will come into the possession or knowledge of the Recipient in
            connection with or as a result of entering into these Terms of Use,
            including information concerning the Discloser’s past, present or
            future customers, suppliers, technology or business, and where
            Discloser is User includes User Data; provided that Discloser’s
            Confidential Information does not include, except with respect to
            Personal Information: (i) information already known or independently
            developed by Recipient without access to Discloser’s Confidential
            Information; (ii) information that is publicly available through no
            wrongful act of Recipient; or (iii) information received by
            Recipient from a third party who was free to disclose it without
            confidentiality obligations.
            <br />
            (b) Confidentiality Covenants. Recipient hereby agrees that during
            the Term and at all times thereafter it will not, except to exercise
            its license rights or perform its obligations under these Terms of
            Use: (i) disclose Confidential Information of the Discloser to any
            person, except to its own personnel or affiliates having a “need to
            know” and that have entered into written agreements no less
            protective of such Confidential Information than these Terms of Use,
            and to such other recipients as the Discloser may approve in
            writing; (ii) use Confidential Information of the Discloser; or
            (iii) alter or remove from any Confidential Information of the
            Discloser any proprietary legend. Each party will take industry
            standard precautions to safeguard the other party’s Confidential
            Information, which will in any event be at least as stringent as the
            precautions that the Recipient takes to protect its own Confidential
            Information of a similar type.
            <br />
            (c) Exceptions to Confidentiality. Notwithstanding Section 8(b),
            Recipient may disclose Discloser’s Confidential Information: (i) to
            the extent that such disclosure is required by applicable law or by
            the order of a court or similar judicial or administrative body,
            provided that, except to the extent prohibited by law, the Recipient
            promptly notifies the Discloser in writing of such required
            disclosure and cooperates with the Discloser to seek an appropriate
            protective order; (ii) to its legal counsel and other professional
            advisors if and to the extent such persons need to know such
            Confidential Information in order to provide applicable professional
            advisory services in connection with the party’s business; or (iii)
            where Recipient is Provisorr, to Customers or Contractors who have
            entered into a Contract to the extent necessary to facilitate such
            Contracts.
            <br />
            <br />
            9. Disclaimer
            <br />
            <br />
            (a) DISCLAIMER. EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS OF USE
            AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, PROVISORR
            DOES NOT WARRANT THAT THE PROVISORR PLATFORM WILL BE UNINTERRUPTED
            OR ERROR FREE OR THAT ALL ERRORS CAN OR WILL BE CORRECTED; NOR DOES
            IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE
            OF THE PROVISORR PLATFORM. EXCEPT AS SPECIFICALLY PROVIDED IN THESE
            TERMS OF USE, THE PROVISORR PLATFORM (OR ANY PART THEREOF), AND ANY
            OTHER PRODUCTS AND SERVICES PROVIDED BY PROVISORR TO USER ARE
            PROVIDED “AS IS” AND “AS AVAILABLE”.
            <br />
            USER ACKNOWLEDGES THAT PROVISORR IS NOT RESPONSIBLE FOR THE ACTIONS
            OR OMISSIONS OF CUSTOMERS, CONTRACTORS OR FOR ANY OF THE CONTRACTOR
            SERVICES OR CLAIMS RELATING THERETO.
            <br />
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, PROVISORR HEREBY
            DISCLAIMS ALL EXPRESS, IMPLIED, COLLATERAL OR STATUTORY WARRANTIES,
            REPRESENTATIONS AND CONDITIONS, WHETHER WRITTEN OR ORAL, INCLUDING
            ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
            MERCHANTABLE QUALITY, COMPATIBILITY, TITLE, NON-INFRINGEMENT,
            SECURITY, RELIABILITY, COMPLETENESS, QUIET ENJOYMENT, ACCURACY,
            QUALITY, INTEGRATION OR FITNESS FOR A PARTICULAR PURPOSE OR USE, OR
            ANY WARRANTIES OR CONDITIONS ARISING OUT OF COURSE OF DEALING OR
            USAGE OF TRADE. WITHOUT LIMITING THE GENERALITY OF ANY OF THE
            FOREGOING, PROVISORR EXPRESSLY DISCLAIMS ANY REPRESENTATION,
            CONDITION OR WARRANTY THAT ANY DATA OR INFORMATION PROVIDED TO USER
            IN CONNECTION WITH USER’S USE OF THE PROVISORR PLATFORM (OR ANY PART
            THEREOF) IS ACCURATE, OR CAN OR SHOULD BE RELIED UPON BY USER FOR
            ANY PURPOSE WHATSOEVER.
            <br />
            <br />
            10. Exclusivity; You acknowledge and agree: <br />
            <br />
            (i) that a substantial value to you is the relationships you make
            with other Users when you identify or are identified by another
            person through the Provisorr Platform (the “Provisorr
            Relationship”); and (ii) for 24 months from the start of a Provisorr
            Relationship (the “Non-Circumvention Period”), you agree to use the
            Provisorr Platform as your exclusive method to request, make, and
            receive all payments for Contractor Services directly or indirectly
            with that person or arising out of your relationship with that
            person and not to circumvent the payment methods offered on the
            Provisorr Platform. If you use the Provisorr Platform as an
            employee, agent, or representative of another business, then the
            Non-Circumvention Period applies to you and other employees, agents,
            or representatives of the business or its successor when acting in
            that capacity with respect to the other User. <br />
            <br />
            11. Indemnities
            <br />
            <br />
            To the fullest extent permitted by applicable law, you will defend,
            indemnify and hold harmless Provisorr, and its officers, directors,
            employees and agents (each, a "Provisorr Indemnitee") from and
            against any and all Losses incurred by an Provisorr Indemnitee
            arising out of or relating to any action (other than by an affiliate
            of an Provisorr Indemnitee) that arise from or relate to: (A) your
            provision of User Data in a manner not compliant with this Agreement
            or the Privacy Policy; (B) your breach of Sections 1(e), 5(b) or 7;
            (C) your unauthorized use Provisorr Platform; (D) any personal
            injury, damage to property or disputes arising from or relating to
            the Contractor Services.
            <br />
            <br />
            12. Limitation of Liabilities
            <br />
            <br />
            (a) The following provisions have been negotiated by the parties and
            reflect a fair allocation of risk and form an essential basis of the
            bargain and will survive and continue in full force and effect
            despite any failure of consideration or of an exclusive remedy
            <br />
            (b) AMOUNT. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND
            EXCEPT AS OTHERWISE PROVIDED IN SECTION 12(d), TO THE MAXIMUM EXTENT
            PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL THE TOTAL AGGREGATE
            LIABILITY OF PROVISORR IN CONNECTION WITH OR UNDER THESE TERMS OF
            USE, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE OR GROSS
            NEGLIGENCE), OR OTHERWISE, EXCEED (I) THE AMOUNT OF THE FEES PAID BY
            YOU IN THE 12 MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING
            RISE TO THE CLAIM; OR (II) IF THE PLATFORM HAS BEEN PROVIDED TO YOU
            WITHOUT THE PAYMENT OF FEES, $100 USD. FOR GREATER CERTAINTY, THE
            EXISTENCE OF ONE OR MORE CLAIMS UNDER THESE TERMS OF USE WILL NOT
            INCREASE THIS MAXIMUM LIABILITY AMOUNT.
            <br />
            (c) TYPE. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND
            EXCEPT AS OTHERWISE PROVIDED IN SECTION 12(d), TO THE MAXIMUM EXTENT
            PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL PROVISORR BE LIABLE
            TO YOU FOR: (I) SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, INCIDENTAL
            OR CONSEQUENTIAL DAMAGES; (II) LOST OR LOSS OF (A) SAVINGS, (B)
            PROFIT, (C) DATA, (D) USE, OR (E) GOODWILL; (III) BUSINESS
            INTERRUPTION; (IV) COSTS FOR THE PROCUREMENT OF SUBSTITUTE PRODUCTS
            OR SERVICES; (V) PERSONAL INJURY OR DEATH; (VI) PERSONAL OR PROPERTY
            DAMAGE ARISING OUT OF OR IN ANY WAY CONNECTED TO THE PROVISORR
            PLATFORM OR THESE TERMS OF USE; OR (VII) ANY CLAIMS ARISING FROM OR
            RELATING TO THE CONTRACTOR SERVICES OR ANY DISPUTES BETWEEN
            CUSTOMERS AND CONTRACTORS REGARDLESS OF CAUSE OF ACTION OR THE
            THEORY OF LIABILITY, WHETHER IN CONTRACT, TORT (INCLUDING
            NEGLIGENCE), OR OTHERWISE, AND EVEN IF NOTIFIED IN ADVANCE OF THE
            POSSIBILITIES OF SUCH DAMAGES.
            <br />
            (d) Exceptions. The exclusions and limitations in Sections 12(a) and
            12(c) do not apply to Losses arising out of or relating to a party’s
            gross negligence or more culpable conduct, including any willful
            misconduct or intentionally wrongful acts.
            <br />
            <br />
            13. Term and Termination
            <br />
            <br />
            (a) Term. These Terms of Use are effective on the day you first use
            the Provisorr Platform and will remain in effect until terminated by
            either party in accordance with the provisions of these Terms of Use
            (the “Term”). We may terminate these Terms of Use at any time and
            with immediate effect by giving notice to you, at our discretion, by
            email (at your current email address on file with us) or through the
            Provisorr Platform. You may terminate these Terms of Use at any time
            and with immediate effect by requesting by email that your Account
            be deleted, ceasing use of the Provisorr Platform and uninstalling
            and deleting the Provisorr Platform. For greater certainty, if you
            continue to use any portion of the Provisorr Platform that is
            publicly available after these Terms of Use have been terminated and
            for as long as Contractor Services are being provided under these
            Terms of Use, these Terms of Use will continue to apply to the
            extent of such use.
            <br />
            (b) Effect of Termination. In the event these Terms of Use terminate
            or expire or suspension of your Account, you remain responsible for
            any amounts owing under these Terms of Use, including all applicable
            Fees.
            <br />
            (c) Survival. The following Sections, together with any other
            provision of these Terms of Use which expressly or by its nature
            survives termination or expiration, or which contemplates
            performance or observance subsequent to termination or expiration of
            these Terms of Use, will survive expiration or termination of these
            Terms of Use for any reason: Section 4 (Data; Intellectual
            Property), Section 5 (Privacy), Section 7 (Fees and Payment),
            Section 8 (Confidential Information), Section 9 (Warranty;
            Disclaimer), Section 12 (Limitation of Liabilities), Section 13(c)
            (Survival), and Section 14 (General Provisions).
            <br />
            <br />
            14. General Provisions
            <br />
            <br />
            (a) Notices. Notices sent to either party will be effective when
            delivered in writing and in person or by email, one day after being
            sent by overnight courier, or five days after being sent by first
            class mail postage prepaid to the official contact designated by the
            party to whom a notice is being given. Notices must be sent: (i) if
            to Provisorr, to the following address:
            <br />
            123 Weatherstone Pl,
            <br />
            Winnipeg, MB R2J 2S9
            <br />
            and (ii) if to User, to the current postal or email address that
            Provisorr has on file with respect to User. Provisorr may change its
            contact information by posting the new contact information on the
            Website or by giving notice thereof to you. You are solely
            responsible for keeping your contact information on file with
            Provisorr current at all times during the Term.
            <br />
            (b) Assignment. You may not assign these Terms of Use to any third
            party without Provisorr’s prior written consent. Provisorr may
            assign these Terms of Use or any rights under these Terms of Use to
            any third party without your consent. These Terms of Use will inure
            to the benefit of and be binding upon the parties, their permitted
            successors and permitted assignees.
            <br />
            (c) Governing Law and Attornment. Except where prohibited by
            applicable law (which may include the province of Québec), these
            Terms of Use and any action related thereto will be governed by and
            construed in accordance with the laws of the Province of Ontario and
            the federal laws of Canada applicable therein, without regard to
            conflicts of law principles. The parties will initiate any lawsuits
            in connection with these Terms of Use in Toronto, Ontario, Canada,
            and irrevocably attorn to the exclusive personal jurisdiction and
            venue of the courts sitting therein. The U.N. Convention on
            Contracts for the International Sale of Goods will not apply to
            these Terms of Use. This choice of jurisdiction does not prevent
            Provisorr from seeking injunctive relief with respect to a violation
            of intellectual property rights or confidentiality obligations in
            any appropriate jurisdiction.
            <br />
            (d) Export Restrictions. You will comply with all export laws and
            regulations that may apply to its access to or use of the Provisorr
            Platform and posting of any Contracts.
            <br />
            (e) Construction. Except as otherwise provided in these Terms of
            Use, the parties’ rights and remedies under these Terms of Use are
            cumulative and are in addition to, and not in substitution for, any
            other rights and remedies available at law or in equity or
            otherwise. The terms “include” and “including” mean, respectively,
            “include without limitation” and “including without limitation.” The
            headings of sections of these Terms of Use are for reference
            purposes only and have no substantive effect. The terms “consent” or
            “discretion” mean the right of a party to withhold such consent or
            exercise such discretion, as applicable, arbitrarily and without any
            implied obligation to act reasonably or explain its decision to the
            other party.
            <br />
            (f) Force Majeure. Neither party will be liable for delays caused by
            any event or circumstances beyond that party’s reasonable control,
            including acts of God, acts of government, flood, fire, earthquakes,
            civil unrest, acts of terror, strikes or other labour problems
            (other than those involving that party’s employees), Internet
            service failures or delays, or the unavailability or Modification by
            third parties of telecommunications or hosting infrastructure or
            third party websites (“Force Majeure”).
            <br />
            (g) Severability. Any provision of these Terms of Use found by a
            tribunal or court of competent jurisdiction to be invalid, illegal
            or unenforceable will be severed from these Terms of Use and all
            other provisions of these Terms of Use will remain in full force and
            effect.
            <br />
            (h) Waiver. A waiver of any provision of these Terms of Use must be
            in writing and a waiver in one instance will not preclude
            enforcement of such provision on other occasions.
            <br />
            (i) Independent Contractors. Provisorr’s relationship to Users is
            that of an independent contractor, and neither party is an agent or
            partner of the other. Neither party will have, and neither party
            will represent to any third party that it has, any authority to act
            on behalf of the other party.
            <br />
            (j) Entire Agreement. These Terms of Use constitutes the entire
            agreement between the parties with respect to the subject matter of
            these Terms of Use and supersedes all prior or contemporaneous
            agreements, representations or other communications between the
            parties, whether written or oral.
            <br />
            (k) Amendments. EXCEPT WHERE PROHIBITED BY APPLICABLE LAW (WHICH MAY
            INCLUDE THE PROVINCE OF QUEBEC), WE RESERVE THE RIGHT TO CHANGE
            THESE TERMS OF USE AT ANY TIME BY POSTING A NEW VERSION TO THE
            PROVISORR PLATFORM. IT IS YOUR OBLIGATION TO MONITOR THE PROVISORR
            PLATFORM FOR ANY SUCH CHANGES. YOUR CONTINUED ACCESS TO OR USE OF
            THE PROVISORR PLATFORM AFTER ANY CHANGES TO THESE TERMS OF USE
            INDICATES YOUR ACCEPTANCE OF SUCH CHANGES. IT IS YOUR RESPONSIBILITY
            TO REVIEW THESE TERMS OF USE REGULARLY.
            <br />
            (l) English Language. It is the express wish of the parties that
            these Terms of Use and all related documents be drawn up in English.
            C’est la volonté expresse des parties que la présente convention
            ainsi que les documents qui s’y rattachent soient rédigés en
            anglais.
            <br />
            <br />
            15. Definitions
            <br />
            <br />
            (a) “Provisorr Platform” means: (i) services through which Provisorr
            hosts and makes available through its online platform connecting
            Customers and Contractors ; (ii) any component or Modification of
            the services referred to in (i); and (iii) the Support Services.
            <br />
            (b) “Loss” or “Losses” means any and all losses, damages, claims,
            actions, judgments, settlements, interest, awards, penalties, fines,
            costs, or expenses of whatever kind, including reasonable legal fees
            and the costs of enforcing any right to indemnification hereunder
            and the cost of pursuing any insurance providers.
            <br />
            (c) “Modifications” means modifications, improvements,
            customizations, patches, bug fixes, updates, enhancements,
            aggregations, compilations, derivative works, translations and
            adaptations, and “Modify” has a corresponding meaning.
            <br />
            (d) “Personal Information” means information about an identifiable
            individual.
            <br />
            (e) “User Data” means any data, information, content, records, and
            files that a User loads or enters into, transmits to, or makes
            available to the Provisorr Platform, including but not limited to
            Personal Information.
          </Typography>
        </div>
      </div>
    </div>
  );
};
