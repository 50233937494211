import PropTypes from "prop-types";
import chat_icon from "../../../asset/image/home_icon/chat.svg";
import check_icon from "../../../asset/image/home_icon/check.svg";
import card_block_icon from "../../../asset/image/home_icon/noCreditCard.svg";
import price_icon from "../../../asset/image/home_icon/priceTag.svg";
import thumbs_up from "../../../asset/image/home_icon/thumbsUp.svg";
import track_icon from "../../../asset/image/home_icon/trackIcon.svg";
import wallet_icon from "../../../asset/image/home_icon/wallet.svg";
import MobileImageThree from "../../../asset/image/home_png/MobileImageThree.png";
import BenefitSection from "./BenefitSection";

const CustomerBenefit = ({ windowSize }) => {
	const customerBenefitsLeft = [
		{
			title: "Chat pros directly or through a post",
			description: "No platform fees. No credit cards required.",
			icon: chat_icon,
		},
		{
			title: "Compare pricing and quality",
			description: "Genuine review and ratings",
			icon: price_icon,
		},
		{
			title: "Chat and confirm your pro",
			description: "Track your order history",
			icon: check_icon,
		},
		{
			title: "Pay directly to pro",
			description: "",
			icon: wallet_icon,
		},
	];

	const customerBenefitsRight = [
		{
			title: "No platform fees. No credit cards required",
			description: "",
			icon: card_block_icon,
		},
		{
			title: "Genuine review and ratings",
			description: "",
			icon: thumbs_up,
		},
		{
			title: "Track your order history",
			description: "",
			icon: track_icon,
		},
	];

	return (
		<div className="mb-8">
			<div className="flex flex-col justify-center pt-28 pb-4">
				<h2 className="text-center text-3xl lg:text-4xl font-extrabold">
					How you will Benefit
				</h2>
				<h2 className="text-center text-3xl lg:text-4xl font-extrabold">
					As a Customer
				</h2>
			</div>

			<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
				<BenefitSection
					windowSize={windowSize}
					benefits={customerBenefitsLeft}
					alignment={"left"}
				/>
				<div className="py-4">
					<div className="w-full flex flex-wrap justify-center">
						<img
							className="object-right h-auto w-auto"
							src={MobileImageThree}
							alt="Mobile App Interface"></img>
					</div>
				</div>

				<BenefitSection
					windowSize={windowSize}
					benefits={customerBenefitsRight}
					alignment={"right"}
				/>
			</div>
		</div>
	);
};

CustomerBenefit.propTypes = {
	windowSize: PropTypes.number.isRequired,
};

export default CustomerBenefit;
