/** node library
 */
import { Chip } from "@material-tailwind/react";
import { CancelOrder } from "../core/FunctionalTask";

/** start
 */

export const column_value = [
  {
    data: "orderId",
    name: "Order ID",
    selector: (row: any) => row.orderId,
    sortable: true,
    minWidth: "8rem",
    omit: true,
  },
  {
    data: "orderTitle",
    name: "Order Name",
    selector: (row: any) => row.orderTitle,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "negotiatedTime",
    name: "Execution Date",
    selector: (row: any) => row.negotiatedTime,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "customerName",
    name: "Customer",
    selector: (row: any) => row.customerName,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "serviceProviderName",
    name: "Service provider",
    selector: (row: any) => row.serviceProviderName,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "status",
    name: "Status",
    selector: (row: any) =>
      row.status === "PROCESSING" ? (
        <Chip variant="filled" color={"blue"} value={row.status} />
      ) : row.status === "REVIEW_DONE" ? (
        <Chip variant="filled" color={"green"} value={row.status} />
      ) : (
        <Chip variant="filled" color={"orange"} value={row.status} />
      ),
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "negotiatedAmount",
    name: "Price",
    selector: (row: any) => "$ " + row.negotiatedAmount,
    sortable: true,
    minWidth: "8rem",
  },
  {
    data: "cancelRequestSolved",
    name: "Cancel Request",
    selector: (row: any) =>
      row.cancelRequestSolved === false ? (
        row.cancelRequested === true ? (
          <CancelOrder orderId={row.orderId} status={row.status}></CancelOrder>
        ) : (
          <Chip variant="filled" color={"blue"} value={"No"} />
        )
      ) : (
        <Chip variant="filled" color={"red"} value={"Cancelled"} />
      ),
    sortable: true,
    minWidth: "8rem",
  },
];
