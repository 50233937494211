import PropTypes from "prop-types";
import chat_call_icon from "../../../asset/image/home_icon/call.svg";
import get_tasks_icon from "../../../asset/image/home_icon/getCustomer.svg";
import growth from "../../../asset/image/home_icon/growth.svg";
import forget_leads_icon from "../../../asset/image/home_icon/leadGeneration.svg";
import no_commissions_icon from "../../../asset/image/home_icon/noCommission.svg";
import get_paid_icon from "../../../asset/image/home_icon/payment.svg";
import schedule_task_icon from "../../../asset/image/home_icon/schedule.svg";
import reviews_ratings_icon from "../../../asset/image/home_icon/thumbsUp.svg";
import wallet_icon from "../../../asset/image/home_icon/wallet.svg";
import MobileImageFour from "../../../asset/image/home_png/MobileImageFour.png";
import BenefitSection from "./BenefitSection";

const ProBenefit = ({ windowSize }) => {
	const ProBenefitsLeft = [
		{
			title: "Forget buying leads",
			icon: forget_leads_icon,
		},
		{
			title: "No commissions",
			icon: no_commissions_icon,
		},
		{
			title: "Get tasks directly from new and existing customers",
			icon: get_tasks_icon,
		},
		{
			title: "Chat or call customers directly without any barriers",
			icon: chat_call_icon,
		},
		{
			title: "Schedule your task ",
			icon: schedule_task_icon,
		},
	];

	const ProBenefitsRight = [
		{
			title: "Get paid directly from customers",
			icon: get_paid_icon,
		},
		{
			title: "Get reviews and ratings for every task",
			icon: reviews_ratings_icon,
		},
		{
			title: "Build your portfolio and grow your business",
			icon: growth,
		},
		{
			title: "Track your earnings",
			icon: wallet_icon,
		},
	];

	return (
		<div className="pb-8 mt-8">
			<div className="flex flex-col justify-center pb-4">
				<h2 className="text-center text-3xl lg:text-4xl font-extrabold">
					How you will Benefit
				</h2>
				<h2 className="text-center text-3xl lg:text-4xl font-extrabold">
					As a Pro
				</h2>
			</div>

			<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
				<BenefitSection
					windowSize={windowSize}
					benefits={ProBenefitsLeft}
					alignment={"left"}
				/>

				<div className="py-4">
					<div className="w-full flex flex-wrap justify-center">
						<img
							className="object-right h-auto w-[75%]"
							src={MobileImageFour}
							alt="Mobile App Interface"></img>
					</div>
				</div>

				<BenefitSection
					windowSize={windowSize}
					benefits={ProBenefitsRight}
					alignment={"right"}
				/>
			</div>
		</div>
	);
};

ProBenefit.propTypes = {
	windowSize: PropTypes.number.isRequired,
};

export default ProBenefit;
