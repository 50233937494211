import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Area,
  XAxis,
  YAxis,
  Line,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import calenderImage from "../../../asset/image/admin_icon/calendar.png";

const Chart = ({
  aspect,
  title,
  dataYear,
  dataMonth,
  dataDay,
  dataPickerType,
  startDateDay,
  startDateMonth,
  startDateYear,
  handleDateSelect,
}) => {
  const [datePickerItem, setDatePickerItem] = useState();
  const [ChartItem, setChartItem] = useState();

  const Weekday = ({ weekday, className, localeUtils, locale }) => {
    const longWeekday = localeUtils.formatWeekdayLong(weekday, locale);
    const newWeekday = longWeekday.substring(0,3);

    return <div className={className}>{newWeekday}</div>;
};

  const datePickerInitializer = () => {
    if (dataPickerType === "year") {
      setDatePickerItem(
        <DatePicker
          disabledKeyboardNavigation
          selected={startDateYear}
          onSelect={(date) => handleDateSelect(date, "Year")} //when day is clicked
          onChange={(date) => handleDateSelect(date, "Year")} //only when value has changed
          showYearPicker
          dateFormat="yyyy"
          yearItemNumber={9}
          required
        />
      );
    } else if (dataPickerType === "month") {
      setDatePickerItem(
        <DatePicker
          selected={startDateMonth}
          onSelect={(date) => handleDateSelect(date, "Month")} //when day is clicked
          onChange={(date) => handleDateSelect(date, "Month")} //only when value has changed
          showMonthYearPicker
          dateFormat="MMMM yy"
          monthItemNumber={12}
          required
        />
      );
    } else if (dataPickerType === "day") {
      setDatePickerItem(
        <DatePicker
       
          selected={startDateDay}
          onSelect={(date) => handleDateSelect(date, "Day")} //when day is clicked
          onChange={(date) => handleDateSelect(date, "Day")} //only when value has changed
          showDayMonthYearPicker
          dateFormat="dd MMMM yy"
          monthItemNumber={7}
          required
        />
      );
    }
  };


  const chartInitializer = () => {
    if (dataPickerType === "year") {
      setChartItem(
        <ResponsiveContainer width="100%" aspect={aspect}>
          <BarChart title={title} width={600} height={300} data={dataYear}>
            <XAxis dataKey="name" />
            <YAxis />
            <Bar dataKey="Total" barSize={30} fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      );
    } else if (dataPickerType === "month") {
      setChartItem(
        <ResponsiveContainer width="100%" aspect={aspect}>
          <BarChart title={title} width={600} height={300} data={dataMonth}>
            <XAxis dataKey="name" />
            <YAxis />
            <Bar dataKey="Total" barSize={30} fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      );
    } else if (dataPickerType === "day") {
      setChartItem(
        <ResponsiveContainer width="100%" aspect={aspect}>
          <BarChart title={title} width={600} height={300} data={dataDay}>
            <XAxis dataKey="name" />
            <YAxis />
            <Bar dataKey="Total" barSize={30} fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      );
    }
  };

  useEffect(() => {
    datePickerInitializer();
  }, [startDateDay, startDateMonth, startDateYear]);


  useEffect(() => {
    chartInitializer();
  }, [dataDay,dataMonth,dataYear]);

  return (
    <div className=" bg-white mt-5 p-3">
      <div className=" mb-5 font-Inter ml-3 text-lg font-bold flex justify-between">
        {title}
        <div className=" outline outline-offset-2 rounded-sm p-2 flex flex-row pr-4">
          <img alt="Clock" src={calenderImage} />
          {datePickerItem}
        </div>
      </div>
      {ChartItem}
    </div>
  );
};

export default Chart;
